export const convert = (date: Date | string, options?: Intl.DateTimeFormatOptions) => {
	return new Date(date).toLocaleString("de-DE", {
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		...options
	});
};
