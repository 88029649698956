import { Environments } from "@stores/user";
import { createContext } from "react";
import { App } from "./models";

interface AppContext {
	app: App;
	env: Environments;
}

export const appContext = createContext<AppContext>({} as AppContext);
export const AppProvider = appContext.Provider;