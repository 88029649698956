import React, {
	forwardRef, useContext,
	useEffect, useRef,
	useState
} from 'react';

import {
	AnimatePresence, motion, useAnimation
} from 'framer-motion';
import useMeasure from 'react-use-measure';
import { renderElement } from '../utils/render-element';

enum TransitionState {
	Animate = 'animate',
	Settled = 'settled',
}

enum Direction {
	Left = -1,
	Right = 1,
}

interface DirectionRef {
	lastStep: number;
	direction: Direction;
}

interface ChildContext {
	/**
	 * Is true if it's the current step.
	 */
	isActive: boolean;
	/**
	 * Is true if width and height are set. This is used to set the first rendered
	 * step to relative to prevent content flashing.
	 */
	isInitialized: boolean;
	/**
	 * This is used to determine the direction of enter/exit animations. For
	 * instance, if the previos step is 2 and the next step is 1 then the
	 * direction is -1. If the previous step is 1 and the next step is 2 then
	 * the direction is 1.
	 */
	direction: Direction;
	/**
	 * This is for the child to know the index of the it's step.
	 */
	stepIndex: number;
	transitionState: TransitionState;
}

const childContext = React.createContext<ChildContext>({} as ChildContext);
const ChildContextProvider = childContext.Provider;

interface HeaderAndFooterProps {
	step: number;
	totalSteps: number;
	prevStep: () => void;
	nextStep: () => void;
}

interface MultistepProps {
	children: (React.ReactElement<StepProps> &
		React.RefAttributes<HTMLDivElement>)[];
	header?: React.ReactNode | React.ElementType<HeaderAndFooterProps>;
	footer?: React.ReactNode | React.ElementType<HeaderAndFooterProps>;
	step?: number;
}

export function Multistep({
	children,
	header,
	footer,
	step: stepProp,
}: MultistepProps) {
	const directionRef = useRef<DirectionRef>({ lastStep: 0, direction: 1 });
	const [transitionState, setTransitionState] = useState<TransitionState>(
		TransitionState.Settled
	);
	const [step, setStep] = useState(stepProp || 0);
	const [ref, rect] = useMeasure();
	const mainControls = useAnimation();

	useEffect(() => {
		if (!stepProp || step === stepProp) {
			return;
		}

		setStepByIndex(stepProp);
	}, [stepProp]);

	useEffect(() => {
		if (!rect.width || !rect.height || transitionState === 'settled') {
			return;
		}

		mainControls.start({ width: rect.width, height: rect.height });
	}, [rect]);

	const setStepByDirection = (direction: Direction) => () => {
		const nextStep = step + direction;
		if (nextStep < 0 || nextStep >= children.length) return;
		setStepByIndex(nextStep);
	};

	const setStepByIndex = (step: number) => {
		// Instantly set width and height of the still active step.
		mainControls.set({ width: rect.width, height: rect.height });

		setTransitionState(TransitionState.Animate);
		setStep(step);
	};

	const _children = React.Children.toArray(children) as typeof children;
	const child = _children[step];

	let direction: Direction = Direction.Right;

	if (directionRef.current.lastStep !== step) {
		if (directionRef.current.lastStep < step) {
			direction = Direction.Right;
		}

		if (directionRef.current.lastStep > step) {
			direction = Direction.Left;
		}

		directionRef.current = {
			direction,
			lastStep: step,
		};
	} else {
		direction = directionRef.current.direction;
	}

	const headerAndFooterProps = {
		step: step + 1,
		totalSteps: _children.length,
		prevStep: setStepByDirection(Direction.Left),
		nextStep: setStepByDirection(Direction.Right),
	};

	return (
		<>
			{header ? (
				<motion.div
					initial={
						child.props.hideHeader && step === 0 ? { height: 0 } : undefined
					}
					animate={
						child.props.hideHeader
							? { height: 0, opacity: 0 }
							: { height: 'auto', opacity: 1 }
					}
					style={{ overflow: 'hidden' }}
				>
					{renderElement(header, headerAndFooterProps)}
				</motion.div>
			) : null}
			<motion.div
				style={{
					position: 'relative',
					overflow: 'hidden',
					width: 'auto',
					height: 'auto',
				}}
				animate={mainControls}
				initial={false}
				onAnimationComplete={() => {
					mainControls.set({ width: 'auto', height: 'auto' });
					setTransitionState(TransitionState.Settled);
				}}
			>
				{_children.map((child, index) => (
					<ChildContextProvider
						key={index}
						value={{
							isActive: index === step,
							isInitialized: Boolean(rect.height && rect.width),
							stepIndex: index,
							direction,
							transitionState,
						}}
					>
						{React.cloneElement<
							StepProps & React.RefAttributes<HTMLDivElement>
						>(child, { ref })}
					</ChildContextProvider>
				))}
			</motion.div>
			{footer ? (
				<motion.div
					initial={
						child.props.hideFooter && step === 0 ? { height: 0 } : undefined
					}
					animate={
						child.props.hideFooter
							? { height: 0, opacity: 0 }
							: { height: 'auto', opacity: 1 }
					}
					style={{ overflow: 'hidden' }}
				>
					{renderElement(footer, headerAndFooterProps)}
				</motion.div>
			) : null}
		</>
	);
}

interface ChildrenProps {
	setStep: (step: number) => void;
	nextStep: () => void;
	prevStep: () => void;
	isLastStep: boolean;
	isFirstStep: boolean;
}

interface StepProps {
	children: React.ReactNode | React.ElementType<ChildrenProps>;
	hideFooter?: boolean;
	hideHeader?: boolean;
}

export const Step = forwardRef<HTMLDivElement, StepProps>(function Step(
	{ children },
	ref
) {
	let context = useContext(childContext);
	let [mounted, setMounted] = useState(false);
	let [position, setPosition] = useState<'absolute' | 'relative'>('relative');

	useEffect(() => {
		// Not using context.isActive directly because the direction would not be
		// correct for the exit animation.
		setMounted(context.isActive);
	}, [context.isActive, setMounted]);

	const transition = {
		initial: { x: 100 * context.direction + '%', opacity: 0 },
		animate: { x: 0, opacity: 1 },
		exit: { x: -100 * context.direction + '%', opacity: 0 },
	};

	return (
		<AnimatePresence initial={false}>
			{mounted ? (
				<motion.div
					style={{
						position:
							context.transitionState === TransitionState.Animate
								? 'absolute'
								: position,
						left: '50%',
						translateX: '-50%',
					}}
					onAnimationComplete={definition => {
						if ((definition as typeof transition['animate']).x === 0) {
							setPosition('relative');
						}
					}}
					{...transition}
					{...(!context.isInitialized && { initial: false })}
				>
					<div
						ref={ref}
						style={{
							width: 'max-content',
							maxWidth: 'min(800px, 100vw - 1rem * 2)',
							minWidth: 250,
						}}
					>
						{renderElement(children)}
					</div>
				</motion.div>
			) : null}
		</AnimatePresence>
	);
});
