import React from 'react';
import { twMerge } from 'tailwind-merge';

interface CardProps {
	className?: string;
}

export const Card: React.FC<CardProps> = ({ children, className }) => {
	return <div className={twMerge('flex flex-col bg-theme shadow', className)}>{children}</div>;
};

interface CardHeaderProps {
	icon?: React.ComponentType<React.SVGProps<SVGSVGElement>> | string;
	title?: React.ReactChild;
	subTitle?: React.ReactChild;
	extra?: React.ReactNode[];
}

export const CardHeader: React.FC<CardHeaderProps> = ({ icon: Icon, title, subTitle, extra, children }) => {
	if (children) {
		return (
			<div className="flex gap-4 items-center border-b border-gray-200 h-12 px-4 flex-shrink-0">
				{Icon ? <Icon className="h-4 w-4" /> : null}
				<span className="font-medium flex-shrink-0">{children}</span>
			</div>
		);
	}

	return (
		<div className="flex items-center h-12 px-3 border-b border-theme-divider flex-shrink-0">
			{!!Icon && <Icon className="h-5 w-5 mr-2" />}
			{!!title && <h4>{title}</h4>}
			{!!subTitle && <span>{subTitle}</span>}
			<div className="flex-grow"></div>
			{!!extra && <div className="flex gap-3">{extra}</div>}
		</div>
	);
};

interface CardHeaderTitleProps {
	icon?: React.ComponentType<React.SVGProps<SVGSVGElement>> | string;
}

export const CardHeaderTitle: React.FC<CardHeaderTitleProps> = ({ icon: Icon, children, ...props }) => {
	const IconClassName = 'h-5 w-5';
	return (
		<div className="flex items-center gap-3 flex-grow" {...props}>
			{Icon ? typeof Icon === 'string' ? <img src={Icon} className={IconClassName} alt="header icon" /> : <Icon className={IconClassName} /> : null}
			<span className="text-lg">{children}</span>
		</div>
	);
};

export const CardBody: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
	return <div className={twMerge('flex-grow p-4 overflow-y-auto', className)} {...props} />;
};
