import { useUserStore } from '@admin/stores/user';
import { Button, Spinner } from '@admin/ui';
import { Container } from '@admin/ui/layouts';
import { capitalizeFirstLetter } from '@admin/utils/capitalize-first-letter';
import { convert } from '@admin/utils/convert-timestamp-to-string';
import { BiCalendar, BiGame, BiShare, BiTrash, BiUser, BiUserCircle } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { FullError } from '../models';
import './SelectedDump.scss';

export const SelectedDump = () => {
	const { id } = useParams();
	const { env } = useUserStore(s => s.user) || {};
	let { data } = useQuery<FullError>(['getError', { id, environment: env }]);

	return data ? (
		<Container>
			<div className="lg:flex lg:items-center lg:justify-between">
				<div className="flex-1 min-w-0">
					<h2 className="text-2xl font-semibold leading-7 text-theme sm:text-2xl sm:truncate">{data.message}</h2>
					<div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
						<div className="mt-2 flex items-center text-sm text-theme-light">
							<BiGame className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
							{data.varSize.toLocaleString('de')} Bytes
						</div>
						<div className="mt-2 flex items-center text-sm text-theme-light">
							<BiCalendar className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
							{convert(data.date)}
						</div>
						<div className="mt-2 flex items-center text-sm text-theme-light">
							<BiUser className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
							Unbekannt
						</div>
					</div>
				</div>
				<div className="mt-5 flex lg:mt-0 lg:ml-4">
					<span className="hidden sm:block ml-3">
						<Button
							type="button"
							iconStart={<BiShare />}
							onClick={() => {
								if (!data) {
									return;
								}

								navigator.share({
									title: 'Fehler / Dump',
									text: capitalizeFirstLetter(`${data.type}: ${data.message}`),
									url: window.location.href
								});
							}}
						>
							Teilen
						</Button>
					</span>

					<span className="sm:ml-3">
						<Button
							type="button"
							iconStart={<BiTrash />}
							status="error"
						>
							Löschen
						</Button>
					</span>
				</div>
			</div>
			<div className="!mt-10" dangerouslySetInnerHTML={{ __html: data.var }} />
		</Container>
	) : (
		<div className="grid place-items-center">
			<Spinner variant="rocket" />
		</div>
	);

	//return data ? (
	//	<Container
	//		title={
	//			<>
	//				<div>
	//					<span className="capitalize text-theme-primary">{data.type}:</span> {data.subject}
	//				</div>
	//				<div className="-mb-2 text-sm text-theme-light">
	//					{convert(data.date)}
	//					<span className="inline-block mx-1 text-theme-primary">&bull;</span>
	//					Fabian Kleinhanß
	//				</div>
	//			</>
	//		}
	//		className=""
	//		// Should be sanitized, but content is only coming from intern devs and
	//		// servers, so it's fine.
	//		dangerouslySetInnerHTML={{ __html: data.var }}
	//		extra={[
	//			<Button key="delete" status="error" iconStart={<AiFillDelete />}>
	//				Löschen
	//			</Button>,
	//		]}
	//	></Container>
	//) : (
	//	<div className="grid place-items-center">
	//		<Spinner variant="rocket" />
	//	</div>
	//);
};
