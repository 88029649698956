import { ConfigSvg, StatsDotsSvg } from '@assets/icons';
import { DataGrid, Multistep, Step } from '@ui';
import { useQuery } from '@admin/libs/react-query';
import { Box, Dialog, DialogContent, MenuItem, Select, Tab, Tabs, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { appContext } from '../context';
import { DialogProps, Caches } from '../models';
import { LoadingStep } from './LoadingStep';

interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

interface CachesDialogProps {}

export const CachesDialog: React.FC<CachesDialogProps & DialogProps> = ({ onClose, open }) => {
	const { env, app } = useContext(appContext);
	const { data, isError } = useQuery<Caches[]>(`caches&appid=${app.APPID}&environment=${env}`, { enabled: open });
	const [selectedServer, setSelectedServer] = useState(0);
	const [selectedCache, setSelectedCache] = useState(0);
	const [tabIndex, setTabIndex] = useState(0);

	if (isError) {
		onClose();
	}

	const caches = Object.entries(data?.[selectedServer]?.caches || {});

	return (
		<Dialog open={open} onClose={onClose}>
			<Multistep step={data ? 1 : 0}>
				{LoadingStep}
				<Step>
					<DialogContent className="w-[500px]">
						{data ? (
							<div className="space-y-4">
								<label className="block">
									Server
									<Select value={selectedServer} onChange={e => setSelectedServer(Number(e.target.value))} className="w-full" size="small">
										{data.map(({ server }, index) => (
											<MenuItem key={index} value={index}>
												{server}
											</MenuItem>
										))}
									</Select>
								</label>
								<label className="block">
									Cache
									<Select value={selectedCache} onChange={e => setSelectedCache(Number(e.target.value))} className="w-full" size="small">
										{Object.keys(data[selectedServer].caches || {}).map((key, index) => (
											<MenuItem key={key} value={index}>
												{key}
											</MenuItem>
										))}
									</Select>
								</label>
								<Tabs value={tabIndex} onChange={(_, newValue) => setTabIndex(newValue)} indicatorColor="primary" textColor="inherit" variant="fullWidth">
									<Tab label="Config" icon={<ConfigSvg className="stroke-current text-theme [&>path]:stroke-inherit" />} />
									<Tab label="Statistiken" icon={<StatsDotsSvg />} />
								</Tabs>
								<TabPanel value={tabIndex} index={0}>
									<DataGrid
										columns={[
											{
												field: 'key',
												headerName: 'Key',
												flex: 1,
											},
											{
												field: 'value',
												headerName: 'Wert',
												flex: 1,
											},
										]}
										rows={Object.entries(caches[selectedCache][1].config).map(([key, value]) => ({ key, value }))}
										getRowId={row => row.key}
										checkboxSelection={false}
										density="compact"
										hideFooter
									/>
								</TabPanel>
								<TabPanel value={tabIndex} index={1}>
									<DataGrid
										columns={[
											{
												field: 'key',
												headerName: 'Key',
												flex: 1,
											},
											{
												field: 'value',
												headerName: 'Wert',
												flex: 1,
											},
										]}
										rows={Object.entries(caches[selectedCache][1].stats).map(([key, value]) => ({ key, value }))}
										getRowId={row => row.key}
										checkboxSelection={false}
										density="compact"
										hideFooter
									/>
								</TabPanel>
							</div>
						) : null}
					</DialogContent>
				</Step>
			</Multistep>
		</Dialog>
	);
};
