import { useUserStore } from '@stores/user';
import { useEffect, useMemo } from 'react';
import { Navigate, useLocation, useNavigate } from "react-router-dom";

interface ProtectedRouteProps {}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
	const { user } = useUserStore()
	const { pathname } = useLocation()
	const navigate = useNavigate()

	const url = useMemo(() => `/login?ref=${encodeURIComponent(pathname)}`, [pathname]);

	useEffect(() => {
		if (!user) {
			navigate(url)
		}
	}, [user, navigate, url]);

	if (!user) {
		return <Navigate to={url} />
	}

	return <>{children}</>
}