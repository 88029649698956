import { QueryClient } from "react-query";
import { defaultQueryFn } from "./default-query-fn";

export const queryClient = new QueryClient({
	defaultOptions: {
		mutations: {},
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
			staleTime: 60 * 1000 * 15,
			queryFn: defaultQueryFn as any,
		},
	},
});
