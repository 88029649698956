import React, { useState } from 'react';
import { Popper as MuiPopper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { twMerge } from 'tailwind-merge';
import { Placement } from '@popperjs/core';

export enum PopperVariants {
	tooltip,
	custom,
	reloadBotProtectionHint,
	profileScreen,
}

const usePopperStyles = makeStyles({
	root: {
		'--arrow-background': '#000',
		'--popper-background': '#000',
		background: 'var(--popper-background)',
		padding: '5px 10px',
		borderRadius: '4px',
		zIndex: 1,
		"&[data-popper-placement^='bottom'] $arrow": {
			top: '-4px',
		},
		"&[data-popper-placement^='bottom-start'] $arrow": {
			transform: 'translateX(8px) !important',
		},
		"&[data-popper-placement^='right'] $arrow": {
			left: '-4px'
		},
		[`&[data-variant='${PopperVariants.tooltip}']`]: {
			fontSize: '0.75rem',
			color: 'white',
			pointerEvents: 'none',
			textAlign: 'center',
		},
		[`&[data-variant='${PopperVariants.custom}']`]: {
			fontSize: '0.75rem',
			textAlign: 'left',
			'--arrow-background': '#fff',
			'--popper-background': '#fff',
			padding: '0',
		},
		[`&[data-variant='${PopperVariants.profileScreen}']`]: {
			'--arrow-background': '#fff',
			'--popper-background': 'transparent',
			padding: '0',
		},
	},
	arrow: {
		'&, &::before': {
			position: 'absolute',
			height: '10px',
			width: '10px',
		},

		'&::before': {
			content: "''",
			background: 'var(--arrow-background)',
			transform: 'rotate(45deg)',
		},
	},
});

//type Variants = 'tooltip' | 'profile-screen';

interface PopperProps {
	variant?: PopperVariants;
	children:
		| React.ReactNode
		| ((props: {
				arrow: JSX.Element;
				placement: Placement;
				TransitionProps?:
					| {
							in: boolean;
							onEnter: () => {};
							onExited: () => {};
						}
					| undefined;
			}) => React.ReactNode);
}

export const Popper: React.FC<React.ComponentProps<typeof MuiPopper> & PopperProps> = ({
	variant = PopperVariants.tooltip,
	modifiers,
	transition,
	className,
	children,
	...props
}) => {
	const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
	const classes = usePopperStyles();

	return (
		<MuiPopper
			className={twMerge(classes.root, className)}
			modifiers={[
				{
					name: 'offset',
					options: {
						offset: [0, 8],
					},
				},
				{
					name: 'arrow',
					enabled: true,
					options: {
						element: arrowRef,
					},
				},
				{
					name: 'flip',
					enabled: true,
					options: {
						altBoundary: true,
						rootBoundary: 'document',
						padding: 8,
					},
				},
			]}
			placement="bottom"
			data-variant={variant}
			transition={transition}
			{...props}
		>
			{transition && typeof children === 'function' ? (
				renderFnProps => children({ ...renderFnProps, arrow: <div className={classes.arrow} ref={setArrowRef}></div> })
			) : (
				<>
					{children}
					<div className={classes.arrow} ref={setArrowRef}></div>
				</>
			)}
		</MuiPopper>
	);
};
