import { queryClient } from '@admin/libs/query-client';
import { MuiProvider } from '@setup/MuiProvider';
import { Toaster } from '@setup/Toaster';
import { Routes } from '@setup/Routes';
import { useApp } from '@setup/use-app';
import './styles/global.scss';
import './styles/mui.scss';
import './styles/notistack.scss';

import axios from 'axios';
import { QueryClientProvider } from 'react-query';
import { MotionConfig } from 'framer-motion';
import { transition } from '@admin/config';

axios.defaults.withCredentials = true;

export const App: React.FC = () => {
	useApp();

	return (
		<QueryClientProvider client={queryClient}>
			<MuiProvider>
				<MotionConfig transition={transition}>
					<Toaster />
					<Routes />
				</MotionConfig>
			</MuiProvider>
		</QueryClientProvider>
	);
};
