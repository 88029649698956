import { useMemo } from "react";
import { useClasses } from "@admin/hooks/use-classes";
import { Divider } from "@mui/material";
import { twMerge } from "tailwind-merge";

interface ContainerProps {
	maxWidth?: "8xl" | "7xl" | "6xl" | "5xl" | "4xl" | "3xl" | "2xl" | "xl" | "lg" | "md" | "sm" | "unset";
	title?: React.ReactChild;
	icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
	disableGutters?: boolean | 'x' | 'y';
	page?: boolean;
	extra?: React.ReactNode[];
}

export const Container: React.FC<Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> & ContainerProps> = ({
	children,
	className,
	disableGutters,
	icon: Icon,
	maxWidth,
	title,
	extra,
	...props
}) => {
	const _maxWidth = useMemo(() => {
		switch (maxWidth) { 
			case "sm":
				return "max-w-sm";
			case "md":
				return "max-w-md";
			case "lg":
				return "max-w-lg";
			case "xl":
				return "max-w-xl";
			case "2xl":
				return "max-w-2xl";
			case "3xl":
				return "max-w-3xl";
			case "4xl":
				return "max-w-4xl";
			case "5xl":
				return "max-w-5xl";
			case "6xl":
				return "max-w-6xl";
			case "7xl":
				return "max-w-7xl";
			case "8xl":
				return "max-w-8xl";
			case "unset":
				return "max-w-none";
		}
	}, [maxWidth]);

	return (
		<div className={
			twMerge(
				"mx-auto w-full max-w-7xl py-8 lg:py-16",
				!disableGutters && "px-4 md:px-12 xl:px-24",
				!!(title || Icon) && "flex flex-col space-y-4 lg:space-y-6"
			)
		}>
			{title ? (
				<>
					<header className={"flex gap-2 lg:gap-4 items-center h-8"}>
						{!!Icon && <Icon className="w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0" />}
						<h1 className={"text-lg md:text-xl lg:text-2xl tracking-tight leading-none"}>{title}</h1>
						<div className="flex-grow"></div>
						{!!extra && <div className="flex gap-3">{extra}</div>}
					</header>
					<Divider />
				</>
			) : null}
			<div className="space-y-4 lg:space-y-6" {...props}>
				{children}
			</div>
		</div>
	);
};
