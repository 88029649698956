import { makeStyles } from "@mui/styles";
import { Player } from "@lottiefiles/react-lottie-player";
import { twMerge } from "tailwind-merge";
import rocketAnimationData from "@assets/lottie-animation-data/spinner-rocket-animation-data.json";

const useLoaderStyles = makeStyles({
	root: {
		position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
	},
	inner: {
		width: "inherit",
    height: "inherit",
    boxSizing: "border-box",
    border: "3px solid",
    borderRadius: "100%",
    borderColor: "#e0e0e0",
    borderRightColor: "#616161",
    animation: "$spin 1s linear infinite",
	},
	"@keyframes spin": {
		from: {
			transform: "rotate(0deg)",
		},
		to: {
			transform: "rotate(360deg)",
		},
	},
});

interface SpinnerProps {
	size?: 'small' | 'default' | 'large';
	variant?: 'primary' | 'secondary' | 'rocket' | 'default';
}

export const Spinner: React.FC<React.HTMLAttributes<HTMLDivElement> & SpinnerProps> = ({ className, size, variant, ...props }) => {
	const classes = useLoaderStyles();

	switch (size) {
		case 'small':
			classes.root += " h-3 w-3";
			classes.inner += " border-[2px]";
			break;
		case 'large':
			classes.root += " h-9 w-9";
			classes.inner += " border-[3px]";
			break;
		default:
			classes.root += " h-6 w-6";
			classes.inner += " border-[2px]";
	}

	switch (variant) {
		case 'primary':
			classes.inner += " border-r-theme-primary";
			break;
		case 'rocket':
			classes.root += " h-10 w-10";
			break;
		default:
			classes.inner += " border-neutral-200 border-r-zinc-600";
	}


	return (
		<div className={twMerge(classes.root, className)} {...props}>
			{variant === "rocket" ? (
				<Player
					loop
					autoplay
					src={rocketAnimationData}
					style={{
						width: 40,
						height: 40
					}}
				/>
			) : (
				<div className={twMerge(classes.inner)}></div>
			)}
		</div>
	);
};
