import { differenceInDays, differenceInHours, differenceInMinutes, differenceInMonths, differenceInWeeks } from 'date-fns';
import { convert } from './convert-timestamp-to-string';

export const formatTime = (time: string | Date): string => {
	let date = time instanceof Date ? time : new Date(time);

	const minutes = differenceInMinutes(new Date(), date);

	if (minutes < 1) {
		return 'gerade eben';
	}

	if (minutes === 1) {
		return 'vor 1 Minute';
	}

	if (minutes < 60) {
		return `vor ${minutes} Minuten`;
	}

	const hours = differenceInHours(new Date(), date);

	if (hours === 1) {
		return `vor 1 Stunde`;
	}

	if (hours < 24) {
		return `vor ${hours} Stunden`;
	}

	const days = differenceInDays(new Date(), date);

	if (days === 1) {
		return 'gestern';
	}

	if (days < 7) {
		return `vor ${days} Tagen`;
	}

	const weeks = differenceInWeeks(new Date(), date);

	if (weeks === 1) {
		return 'letzte Woche';
	}

	if (weeks < 4) {
		return `vor ${weeks} Wochen`;
	}

	const months = differenceInMonths(new Date(), date);

	if (months === 1) {
		return 'vor 1 Monat';
	}

	if (months < 12) {
		return `vor ${months} Monaten`;
	}

	return convert(time);
};
