import { AlertOctagonSvg, ExternalLinkSvg, LayersSvg, MessageCircleSvg, MoreVerticalSvg, RefreshCcwSvg, TypeSvg } from '@assets/icons';
import { Card, CardBody } from '@ui';
import { useQuery } from '@admin/libs/react-query';
import { Environments, useUserStore } from '@stores/user';
import { App } from './models';

import { CachesDialog } from './components/CachesDialog';
import { ErrorsDialog } from './components/ErrorsDialog';
import { MissingDialog } from './components/MissingDialog';
import { MessagesDialog } from './components/MessagesDialog';

import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Skeleton, Stack } from '@mui/material';
import { useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Container } from '@ui/layouts';
import { AppProvider } from './context';

const transformString = 'c_crop,h_1080,w_1920,y_0/c_scale,h_240,w_426';

// TODO: UPDATE IN DATABASE INSTEAD OF HARDCODED!
const cloudinaryImgMap = {
	'e-hoi.de': `https://res.cloudinary.com/dr81zaxbd/image/upload/${transformString}/v1656598427/e-hoi_AD/screencapture-e-hoi-de-2022-06-30-16_10_34_ev2csl.webp`,
	'e-hoi.ch': `https://res.cloudinary.com/dr81zaxbd/image/upload/${transformString}/v1656598427/e-hoi_AD/screencapture-e-hoi-ch-2022-06-30-16_11_47_frhfro.webp`,
	'cruisewinkel.nl': `https://res.cloudinary.com/dr81zaxbd/image/upload/${transformString}/v1656598427/e-hoi_AD/screencapture-cruisewinkel-nl-2022-06-30-16_12_03_yav2en.webp`,
	'wondercruises.be': `https://res.cloudinary.com/dr81zaxbd/image/upload/${transformString}/v1656598427/e-hoi_AD/screencapture-wondercruises-be-2022-06-30-16_12_42_ntzwoi.webp`,
	'zeetours.nl': `https://res.cloudinary.com/dr81zaxbd/image/upload/${transformString}/v1656598427/e-hoi_AD/screencapture-zeetours-nl-2022-06-30-16_13_21_kljlnq.webp`,
	'e-hoi.de bewertung': `https://res.cloudinary.com/dr81zaxbd/image/upload/${transformString}/v1656677809/e-hoi_AD/screencapture-e-hoi-de-bewertungabgeben-2022-07-01-14_16_27_sbkur7.webp`,
	'e-hoi cruisebooker': `https://res.cloudinary.com/dr81zaxbd/image/upload/${transformString}/v1656677809/e-hoi_AD/screencapture-e-hoi-edtest-local-cruisebooker-ps-1-2022-07-01-13_42_13_oowgkn.webp`,
	'e-hoi.de login': `https://res.cloudinary.com/dr81zaxbd/image/upload/${transformString}/v1656677809/e-hoi_AD/screencapture-e-hoi-edtest-local-login-2022-07-01-13_46_03_q4vdrw.webp`,
};

interface AppsControllerProps {}

const AppsController: React.FC<AppsControllerProps> = () => {
	const { data: appsData } = useQuery<App[]>('listApps');
	const env = useUserStore().user?.env || 'dev';

	if (!env) {
		return <Navigate to="/dashboard" />;
	}

	return (
		<Container icon={LayersSvg} title="Anwendungen">
			<div className="grid gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:gap-4">
				{appsData
					? appsData.map((app, index) => (
							<div key={index}>
								<Card>
									<img
										src={
											(cloudinaryImgMap as Record<string, string>)[app.APPNAME] ||
											`http://dev.e-hoi.edtest.local/raphael.schuerholz/apps/egruppeapps/img/apps/${app.PICTURE}`
										}
										alt="app preview"
										className="object-cover aspect-video"
									/>
									<CardBody>
										<Stack alignItems="center" justifyContent="space-between" direction="row">
											<Stack direction="row" alignItems="center">
												<IconButton
													edge="start"
													onClick={() => window.open((app as Record<string, any>)[`URL_${env.toUpperCase()}`] || app.URL_DEV, '_blank', 'noopener,noreferrer')}
												>
													<ExternalLinkSvg className="w-4 h-4" />
												</IconButton>
												{app.APPNAME}
											</Stack>
											<AppProvider value={{ env: (useUserStore.getState().user?.env || 'dev') as Environments, app }}>
												<More />
											</AppProvider>
										</Stack>
									</CardBody>
								</Card>
							</div>
					  ))
					: Array(8)
							.fill('')
							.map((_, index) => (
								<div key={index}>
									<Card>
										<Skeleton className="h-full aspect-video" animation="wave" variant="rectangular" />
										<CardBody>
											<Skeleton height="1rem" animation="wave" variant="rectangular" />
										</CardBody>
									</Card>
								</div>
							))}
			</div>
		</Container>
	);
};

const actions = [
	{
		label: 'Fehlende Textkonstanten',
		icon: TypeSvg,
		dialog: MissingDialog,
	},
	{
		label: 'Cache',
		icon: RefreshCcwSvg,
		dialog: CachesDialog,
	},
	{
		label: 'Nachrichten',
		icon: MessageCircleSvg,
		dialog: MessagesDialog,
	},
];

interface MoreProps {}

export const More: React.FC<MoreProps> = () => {
	const anchorRef = useRef<HTMLDivElement>(null);
	const [panelOpen, setPanelOpen] = useState(false);
	const [dialogIndex, setDialogIndex] = useState<number | null>(null);

	return (
		<>
			<IconButton component="div" ref={anchorRef} edge="end" onClick={setPanelOpen.bind(null, !panelOpen)}>
				<MoreVerticalSvg className="w-4 h-4" />
			</IconButton>
			<Menu
				id="basic-menu"
				anchorEl={anchorRef.current}
				open={panelOpen}
				onClose={setPanelOpen.bind(null, false)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				MenuListProps={{
					dense: true,
				}}
			>
				{actions.map(({ label, icon: Icon }, index) => (
					<MenuItem
						onClick={() => {
							setDialogIndex(index);
							setPanelOpen(false);
						}}
						key={index}
					>
						<ListItemIcon>
							<Icon className="w-4 h-4" />
						</ListItemIcon>
						<ListItemText>{label}</ListItemText>
					</MenuItem>
				))}
			</Menu>
			{/* Cannot pass fragment to Menu component */}
			{actions.map(({ dialog: Dialog }, index) => (
				<Dialog key={index} onClose={setDialogIndex.bind(null, null)} open={dialogIndex === index} />
			))}
		</>
	);
};

export const AppsPage = AppsController;
