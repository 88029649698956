import { useState } from 'react';

import { useQuery } from 'react-query';
import { Link } from '@mui/material';
import { useUserStore, Permission, User } from '@stores/user';
import { Navigate, useSearchParams } from 'react-router-dom';

import errorAnimationData from '@assets/lottie-animation-data/error-animation-data.json';
import loadingAnimationData from '@assets/lottie-animation-data/spinner-rocket-animation-data.json';
import { Player } from '@lottiefiles/react-lottie-player';

interface LoginPageProps {}

export const LoginPage: React.FC<LoginPageProps> = () => {
	const [searchParams] = useSearchParams();

	// Not using the native isError prop from react-query, because it is always
	// false when retry is enabled.
	const [isError, setIsError] = useState(false);

	const { isLoading, isSuccess } = useQuery<User>('authenticate', {
		onSuccess: ({ rules, userID, user, title, env }) => {
			useUserStore.setState({
				permissions: (rules.split(',') || []) as Permission[],
				user: {
					userId: userID,
					username: user,
					fullName: title,
					env
				},
			});
		},
		retry: () => {
			setIsError(true);
			return true;
		},
		retryDelay: 5 * 1000,
		refetchOnWindowFocus: true
	});

	const size = 90;
	const ref = searchParams.get("ref");

	if (isSuccess) {
		return <Navigate to={ref ? decodeURIComponent(ref) : "/dashboard"} />;
	}

	return (
		<div className="flex-grow grid place-items-center">
			{isError && (
				<div className="flex flex-col flex-center">
					<Player
						loop
						autoplay
						src={errorAnimationData}
						style={{
							width: size,
							height: size,
						}}
					/>
					<span className="block max-w-xl text-center text-sm">
						Sie sind nicht zum Beitritt dieser Seite autorisiert. Bitte melden Sie sich{' '}
						<Link href="https://backend.e-hoi.de/backend/express/" target="_blank">
							hier
						</Link>{' '}
						mit Ihrem Benutzernamen und Passwort an. Wenn Sie denken, dass es sich um einen Fehler handelt, kontaktieren Sie bitte den Administrator.
					</span>
				</div>
			)}
			{isLoading && !isError && (
				<div>
					<Player
						loop
						autoplay
						src={loadingAnimationData}
						style={{
							width: 40,
							height: 40
						}}
					/>
				</div>
			)}
		</div>
	);
};