import { Toaster as HotToaster } from 'react-hot-toast';

export const Toaster = () => {
	return (
		<HotToaster
			position="bottom-right"
			toastOptions={{
				duration: 6000,
				style: {
					borderRadius: 0,
					gap: '0.5rem',
					maxWidth: 500,
				},
				className: 'shadow-lg',
			}}
			containerStyle={{
				zIndex: 50,
			}}
			reverseOrder
		/>
	)
}