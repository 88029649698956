import React, { useState } from 'react';

import { ChevronsRightSvg, HomeSvg, LayersSvg, MessageErrorSvg, ServerSimpleSvg, WebsiteLogoSvg, XSvg } from '@assets/icons';
import { Button, Popper, PopperVariants } from '@ui';
import { useSettingsStore } from '@stores/settings';

import { ButtonBase, Divider, Fade } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

export const Sidebar: React.FC = () => {
	return (
		<aside className="fixed top-0 z-20 hidden w-0 h-screen border-r bg-theme-sidebar shadow-sidebar md:block md:w-12 lg:w-60 border-theme-divider">
			<div className="relative flex items-center h-16 border-b border-theme-divider">
				<WebsiteLogoSvg className="hidden ml-4 max-h-6 lg:block" />
			</div>
			<nav className="mt-12 space-y-6">
				<SidebarNavigation
					groupLabel="Allgemein"
					items={[
						{
							label: 'Startseite',
							path: '/',
							icon: HomeSvg,
						},
						{
							label: 'Server',
							path: 'server',
							icon: ServerSimpleSvg,
						},
						{
							label: 'Anwendungen',
							path: 'applications',
							icon: LayersSvg,
						},
						{
							label: 'Fehler / Dumps',
							path: 'dumps',
							icon: MessageErrorSvg,
						},
					]}
				/>
				<Divider className="mx-4" />
				<SidebarNavigation
					groupLabel="Bot-Schutz"
					items={[
						{
							label: 'IPv4',
							path: 'bot-protection/ip',
							icon: ChevronsRightSvg,
						},
						{
							label: 'User Agent',
							path: 'bot-protection/ua',
							icon: ChevronsRightSvg,
						},
					]}
				/>
			</nav>
		</aside>
	);
};

type Item = {
	label: string;
	path: string;
	icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
};

interface SidebarNavigationProps {
	items: Item[];
	groupLabel?: string;
}

const SidebarNavigation: React.FC<SidebarNavigationProps> = ({ items, groupLabel }) => {
	const { sidebarCollapsed } = useSettingsStore();

	return (
		<div className={'space-y-2'}>
			<span className={`hidden lg:block text-theme-light text-sm font-medium -top-3.5 tracking-wide px-4 pb-1 ${sidebarCollapsed ? 'opacity-0 invisible' : ''}`}>
				{groupLabel}
			</span>
			<ol>
				{items.map(({ label, icon: Icon, path }) => (
					<SidebarNavigationItem key={path} label={label} path={path} icon={Icon} />
				))}
			</ol>
		</div>
	);
};

interface SidebarNavigationItemProps {
	label: string;
	path: string;
	icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

function SidebarNavigationItem({ label, path, icon: Icon }: SidebarNavigationItemProps) {
	const className = 'block w-full transition-colors cursor-pointer text-theme';
	const activeClassName = 'w-full bg-theme-primary bg-opacity-10 !text-theme-primary';

	const { pathname } = useLocation();
	const [ref, setRef] = useState<HTMLLIElement | null>(null);
	const showHintPopper = useSettingsStore(s => s.showShouldReloadBotProtection && !s.disableShouldReloadBotProtectionHint) && path === "server";

	return (
		<li key={label} className={'transition-colors focus:bg-gray-50 relative'} ref={setRef}>
			<NavLink to={path}>
				{({ isActive }) => {
					if (pathname === '/dashboard' && path === "/") {
						isActive = true;
					}
					
					return (
					<ButtonBase className={twMerge(className, isActive && activeClassName, showHintPopper && "border border-red-600 border-dashed")} role="link">
						<div className={'w-full flex items-center px-4 py-2.5 lg:hover:translate-x-1 transition-transform'}>
							<div className="basis-8">
								<Icon className={'h-4 w-4 stroke-current'} />
							</div>
							<span className={'leading-none hidden lg:inline-block'}>{label}</span>
						</div>
					</ButtonBase>
				)}}
			</NavLink>
			<Popper
				open={showHintPopper}
				anchorEl={ref}
				variant={PopperVariants.custom}
				placement="right-start"
				className="max-w-[18rem] w-[200%] text-left border border-theme-divider shadow-lg"
				transition
				disablePortal
			>
				{({ TransitionProps, arrow }: any) => (
					<Fade {...TransitionProps} >
						<div className="p-4 rounded">
							{arrow}
							<h3 className="text-theme">Achtung 🔥</h3>
							<p className="inline-block mt-3 text-theme-light">
								Damit Ihre Änderungen wirksam werden, müssen die Einträge auf den Servern neugeladen werden!
							</p>
							<div className="mt-3">
								<Button
									iconStart={<XSvg />}
									variant="underlined"
									className="text-red-500"
									onClick={() => useSettingsStore.setState({ disableShouldReloadBotProtectionHint: true })}
								>
									Nicht mehr anzeigen
								</Button>
							</div>
						</div>
					</Fade>
				)}
			</Popper>
		</li>
	);
}
