import React from "react";

import { Card, CardBody } from "@ui/Card";
import { OvalSvg } from "@assets/icons";

import { Dialog } from "@mui/material";
import { withStyles } from "@mui/styles";

const StyledDialog = withStyles({
	container: {
		"& > *": {
			maxWidth: "45rem",
			margin: "2rem",
			boxShadow: "0 0 80px -24px rgba(0,0,0,0.33)",
		},
	},
})(Dialog);

const Paper: React.FC<any> = ({ children, ...props }) => {
	return <>{children}</>;
};

type Variants =
	| {
			variant?: "loading";
			loadingState?: {
				current: number;
				target: number;
			};
	  }
	| {
			variant?: "";
			loadingState?: never;
	  };

type ModalProps = React.ComponentProps<typeof Dialog> & Variants;

export const Modal: React.FC<ModalProps> = ({ children, variant, loadingState, ...props }) => {
	const child = React.Children.count(children) && React.Children.only(children);

	let body;

	switch (variant) {
		case "loading": {
			body = (
				<Card>
					<CardBody>
						<div className="flex items-center justify-center p-4 gap-4">
							<OvalSvg className="h-8 w-8 stroke-current text-gray-700" />
							<span>{`Wird geladen... ${loadingState ? `(${loadingState.current}/${loadingState.target}) ` : ""}`}</span>
						</div>
					</CardBody>
				</Card>
			);
			break;
		}
		default: {
			body = child;
		}
	}

	return (
		<StyledDialog PaperComponent={Paper} {...props}>
			{body}
		</StyledDialog>
	);
};
