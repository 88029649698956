import { ArrowSvg, EventSvg, FilePlusSvg, FileShredderSvg, FileTextSvg, HomeSvg, RefreshSvg } from '@assets/icons';
import { Card, CardBody, CardHeader, DataGrid } from '@ui';
import { Container } from '@ui/layouts';
import { useQuery } from '@admin/libs/react-query';
import { convert } from '@utils/convert-timestamp-to-string';
import { EventsMap } from './components/events-map';
import { StatisticsCard } from './components/StatisticsCard';
import { Change, DashboardData } from './models';
import { formatTime } from '@admin/utils/format-time';

import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { diffChars } from 'diff';
import { isValid, max, min } from 'date-fns';

const useStyles = makeStyles({
	root: {
		'&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
			outline: 'none',
		},
	},
});

interface MainPageProps {}

export const MainPage: React.FC<MainPageProps> = () => {
	const dashboard = useQuery<DashboardData>('dashboard', { refetchOnMount: true, staleTime: 0 });
	const classes = useStyles();

	const dates = dashboard.data?.changes.map(change => new Date(change.CreatedDate)) || [];
	let minDate = min(dates);
	let maxDate = max(dates);

	return (
		<Container
			icon={HomeSvg}
			title={
				<>
					Startseite{' '}
					<span className="text-theme-primary">Admin Dashboard</span>
				</>
			}
		>
			{/*<Example />*/}
			<div className="grid gap-4 lg:gap-6 lg:grid-cols-3">
				<StatisticsCard color="text-sky-500" label="IPv4-Tabelle" sub="Änderungsaktivitäten" data={dashboard.data?.totals.ip || []} />
				<StatisticsCard color="text-fuchsia-500" label="UA-Tabelle" sub="Änderungsaktivitäten" data={dashboard.data?.totals.ua || []} />
				<StatisticsCard color="text-green-500" label="TK/SV" sub="Aktualisierungen" data={dashboard.data?.totals.cs || []} />
			</div>
			<Card>
				<CardHeader
					icon={EventSvg}
					title='Ereignisse'
					extra={[
						isValid(minDate) && isValid(maxDate) ? <span className="hidden text-sm text-theme-light lg:block" key="changes">{`${convert(min(dates))} - ${convert(max(dates))}`}</span> : null,
					]}
				/>
				<CardBody>
					<DataGrid
						headerHeight={0}
						className={classes.root}
						disableColumnMenu
						disableColumnFilter
						checkboxSelection={false}
						disableSelectionOnClick
						hideFooter
						density="compact"
						localeText={{
							noRowsLabel: dashboard.isFetched ? 'Keine Daten verfügbar' : 'Wird geladen',
						}}
						pageSize={100}
						columns={[
							{
								field: 'EventId',
								headerName: 'Ereignisart',
								width: 150,
								sortable: false,
								renderCell: (params: GridRenderCellParams<keyof typeof EventsMap>) => {
									let tableName = EventsMap[params.value] || 'Unbekannt';
									return <Chip label={tableName} variant="outlined" size="small" />;
								},
							},
							{
								field: 'Value',
								headerName: 'Wert',
								sortable: false,
								flex: 1,
								minWidth: 200,
								renderCell: (params: GridRenderCellParams) => {
									const { FromValue, ToValue, EventId } = params.row as Change;

									const iconProps: Pick<React.SVGAttributes<SVGSVGElement>, 'className'> = { className: 'w-4 h-4 mr-2' };
									let renderValue: React.ReactNode[] = [];

									// 'IPv4' and 'User Agent'
									if ([1, 2].includes(EventId)) {
										// New entry
										if (FromValue === '') {
											renderValue.push(<FilePlusSvg key="plus" {...iconProps} />);
										}

										// Deleted entry
										if (ToValue === '') {
											renderValue.push(<FileShredderSvg key="shredder" {...iconProps} />);
										}

										// Changed entry
										if (FromValue !== '' && ToValue !== '') {
											renderValue.push(<FileTextSvg key="text" {...iconProps} />);
										}

										diffChars(FromValue, ToValue).forEach((part, index) => {
											const className = part.added ? 'text-green-600' : part.removed ? 'text-red-600' : '';
											renderValue.push(
												<span key={index + part.value} className={className}>
													{part.value}
												</span>
											);
										});
									}

									// 'Textkonstanten', 'Servervariablen' and 'Blockierte Einträge'
									if ([10, 11, 12].includes(EventId)) {
										renderValue.push(<RefreshSvg key="refresh" {...iconProps} className="w-2.5 h-2.5 ml-0.5 mr-2 fill-theme-primary" />);
									}

									return renderValue;
								},
							},
							{
								field: 'UserTitle',
								headerName: 'Benutzer',
								width: 200,
								sortable: false,
								renderCell: (params: GridRenderCellParams) => params.value || 'Entwickler',
							},
							{
								field: 'CreatedDate',
								headerName: '',
								width: 200,
								sortable: false,
								renderCell: (params: GridRenderCellParams) => {
									return formatTime(params.value);
								},
							},
						]}
						rows={dashboard.data?.changes || []}
					/>
				</CardBody>
			</Card>
		</Container>
	);
};

const stats = [
  { name: 'Total Subscribers', stat: '71,897', previousStat: '70,946', change: '12%', changeType: 'increase' },
  { name: 'Avg. Open Rate', stat: '58.16%', previousStat: '56.14%', change: '2.02%', changeType: 'increase' },
  { name: 'Avg. Click Rate', stat: '24.57%', previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

function Example() {
  return (
    <div>
      <h3 className="text-lg font-medium leading-6 text-theme">Letzten 30 Tage</h3>
      <dl className="grid grid-cols-1 mt-5 overflow-hidden divide-y shadow bg-theme divide-theme-divider md:grid-cols-3 md:divide-y-0 md:divide-x">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-theme">{item.name}</dt>
            <dd className="flex items-baseline justify-between mt-1 md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-theme-primary">
                {item.stat}
                <span className="ml-2 text-sm font-medium text-theme-light">from {item.previousStat}</span>
              </div>

              <div
                className={classNames(
                  item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                  'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                )}
              >
                {item.changeType === 'increase' ? (
                  <ArrowSvg
                    className="-ml-1 mr-0.5 flex-shrink-0 self-center h-3 w-3 fill-current text-green-500 transform -rotate-90"
                    aria-hidden="true"
                  />
                ) : (
                  <ArrowSvg
                    className="-ml-1 mr-0.5 flex-shrink-0 self-center h-3 w-3 fill-current text-red-500 transform rotate-90"
                    aria-hidden="true"
                  />
                )}

                <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                {item.change}
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}