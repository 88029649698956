import create, { GetState, SetState } from "zustand";
import { persist } from "zustand/middleware";

interface SettingsStore {
	sidebarCollapsed: boolean;
	showShouldReloadBotProtection: boolean;
	disableShouldReloadBotProtectionHint: boolean;
	setShouldReloadBotProtectionHintActive: ReturnType<typeof setShouldReloadBotProtectionHintActive>;
	reloadedServers: string[];
	theme: 'light' | 'dark';
}

function setShouldReloadBotProtectionHintActive(set: SetState<SettingsStore>, _: GetState<SettingsStore>) {
	return () => {
		set({ showShouldReloadBotProtection: true, reloadedServers: [] });
	}
}

export const useSettingsStore = create<SettingsStore>(
	persist(
		(set, get) => ({
			sidebarCollapsed: false,
			showShouldReloadBotProtection: false,
			disableShouldReloadBotProtectionHint: false,
			setShouldReloadBotProtectionHintActive: setShouldReloadBotProtectionHintActive(set, get),
			reloadedServers: [],
			theme: 'light'
		}),
		{
			name: "__settings",
		}
	)
);
