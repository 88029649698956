import { useRerender } from '@admin/hooks/use-rerender';
import { Button } from '@admin/ui';
import { formatTime } from '@admin/utils/format-time';
import { Skeleton } from '@mui/material';
import { memo, useEffect, useRef } from 'react';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { BiCalendar, BiChevronRight, BiCustomize } from 'react-icons/bi';
import { FcSearch } from 'react-icons/fc';
import { RiEmotionSadLine } from 'react-icons/ri';
import { Link, useParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
	ListOnItemsRenderedProps,
	VariableSizeList as List,
} from 'react-window';
import FuzzyHighlighter, { Highlighter } from 'react17-fuzzy-highlighter';
import { ErrorBase } from '../models';

type Highlighted = {
	text: string;
	isHighlighted: boolean;
}[];

function highlight(text: string | Highlighted) {
	return Array.isArray(text) ? <Highlighter text={text} /> : text;
}

const defaultItemHeight = 80;

const itemPropsThatAffectLayout = [
	'remote_addr',
	'script_name',
	'server',
	'user_agent',
] as const;

const getItemsSize = (item?: ErrorBase | Record<string, Highlighted>) => {
	let arrayCount = 0;

	if (!item || item.type === 'dump') {
		return defaultItemHeight;
	}

	for (const key of itemPropsThatAffectLayout) {
		if (Array.isArray(item[key])) {
			arrayCount++;
		}
	}

	return (
		defaultItemHeight +
		arrayCount *
			// font-size
			(12 +
				// margin-top
				4)
	);
};

interface VirtualizedListProps {
	data?: ErrorBase[];
	query?: string;
	onItemsRendered?: (props: ListOnItemsRenderedProps) => any;
}

const _VirtualizedList: React.FC<VirtualizedListProps> = ({
	data,
	query,
	onItemsRendered,
}) => {
	const listRef = useRef<List>(null);
	const slug = useParams();
	const dumpId = slug?.['*'];

	// Force rerender to update the timestamp every minute.
	const forceRerender = useRerender();

	useEffect(() => {
		/**
		 * Use useSyncExternalStore when updated to react 18.
		 */
		const interval = setInterval(() => {
			forceRerender();
		}, 60 * 1000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	useEffect(() => {
		/**
		 * Size is not automatically updated on state changes.
		 */
		listRef.current?.resetAfterIndex(0);
	}, [query]);

	let initialScrollOffset: number | undefined;

	if (dumpId && data) {
		for (const index in data) {
			if (data[index].id === dumpId) {
				initialScrollOffset = defaultItemHeight * Number(index);
				break;
			}
		}
	}

	return data ? (
		<FuzzyHighlighter
			query={query || ''}
			data={data}
			options={{
				shouldSort: true,
				includeMatches: true,
				threshold: 0.6,
				location: 0,
				distance: 100,
				keys: ['message', ...itemPropsThatAffectLayout],
			}}
		>
			{({ formattedResults }) => {
				const results = query ? formattedResults : data || [];

				if (results.length === 0 && query !== '') {
					return (
						<div className="text-center p-4 mt-4">
							<RiEmotionSadLine className="mx-auto h-8 w-8" />
							<h4 className="mt-2 font-medium">Keine Ergbenisse</h4>
							<p className="mt-1 text-sm text-theme-light">
								Versuchen Sie es mit einem anderen Suchbegriff.
							</p>
						</div>
					);
				}

				if (results.length === 0) {
					return (
						<div className="text-center p-4 mt-4">
							<FcSearch className="mx-auto h-8 w-8" />
							<h4 className="mt-2 font-medium">
								Keine Fehler / Dumps gefunden
							</h4>
							<p className="mt-1 text-sm text-theme-light">
								Starten Sie durch das Einrichten eigener Dumps. Eine Schritt
								für Schritt Anleitung finden Sie{' '}
								<span className="text-blue-500 underline cursor-pointer">
									hier
								</span>
								.
							</p>
						</div>
					);
				}

				return (
					<div className="flex-grow">
						<AutoSizer>
							{rect => (
								<List
									{...rect}
									itemCount={query ? results.length : results.length || 10}
									itemSize={index =>
										getItemsSize(formattedResults[index]?.formatted)
									}
									innerElementType="ul"
									onItemsRendered={onItemsRendered}
									overscanCount={4}
									initialScrollOffset={initialScrollOffset}
									ref={listRef}
								>
									{({ index, style }) => {
										const item = {
											...data?.[index],
											...formattedResults[index]?.item,
											...formattedResults[index]?.formatted,
										};

										return (
											<ListItem
												item={item}
												style={style}
												isActive={dumpId === item.id}
												key={item.id || index}
											/>
										);
									}}
								</List>
							)}
						</AutoSizer>
					</div>
				);
			}}
		</FuzzyHighlighter>
	) : (
		<ul>
			{Array.from({ length: 10 }).map((_, i) => (
				// Empty placeholder
				<ListItem
					style={{ height: defaultItemHeight, position: 'relative' }}
					key={i}
				/>
			))}
		</ul>
	);
};

interface ListItemProps {
	item?: ErrorBase;
	isActive?: boolean;
}

const ListItem: React.FC<
	ListItemProps & React.HTMLAttributes<HTMLLIElement>
> = ({ item, isActive, ...props }) => {
	return (
		<li
			{...props}
			className="!left-4 !w-[calc(100%_-_2rem)] border-b border-theme-divider"
		>
			<Link
				to={item?.id || '#'}
				className={`flex items-center h-full px-4 -mx-4 hover:bg-theme-hover ${
					isActive ? 'bg-theme-primary/10' : ''
				}`}
			>
				<div className="flex-1 min-w-0 sm:flex sm:items-center sm:justify-between">
					<div className="truncate">
						{item ? (
							<p
								className={[
									'font-medium truncate block text-sm',
									item.type === 'dump'
										? 'text-theme-primary'
										: 'text-red-500',
								].join(' ')}
							>
								{highlight(item.message)}
							</p>
						) : (
							<Skeleton height={16} width={150} variant="text" />
						)}
						<div className="flex mt-2 items-center text-sm text-theme-light">
							{item ? (
								<>
									<p>{formatTime(item.date)}</p>
									<span className="inline-block mx-2 text-theme-primary">&bull;</span>
									<p>{Math.round(item.varSize / 1000).toFixed()} KB</p>
								</>
							) : (
								<Skeleton height={16} width={100} variant="text" />
							)}
						</div>
						{item?.type === 'error' ? (
							<div
								className={`text-sm max-w-[250px] overflow-hidden ${
									itemPropsThatAffectLayout.some(
										i => item[i] && Array.isArray(item[i])
									)
										? 'mt-2'
										: ''
								}`}
							>
								{itemPropsThatAffectLayout.map(key =>
									item[key] && Array.isArray(item[key]) ? (
										<pre className="text-theme-light text-xs hover:bg-theme-navbar" key={key}>
											<span className="capitalize text-theme-primary">
												{key.padEnd(11)}:
											</span>{' '}
											{highlight(item[key])}
										</pre>
									) : null
								)}
							</div>
						) : null}
					</div>
				</div>
				<div className="flex-shrink-0 ml-5">
					<BiChevronRight
						className="w-5 h-5 text-theme-lighter"
						aria-hidden="true"
					/>
				</div>
			</Link>
		</li>
	);
};

export const VirtualizedList = memo(_VirtualizedList, (prev, next) => {
	return prev.data?.length === next.data?.length && prev.query === next.query;
});
