import { persist } from "zustand/middleware";
import create from "zustand";

export type Changes = {
	[rowId: string]: {
		[fieldName: string]: string;
	};
};

export type CreateUseChangesStore = {
	changes: Changes;
	setChanges: (cb: Changes | ((state: Changes) => Changes)) => void;
	removeValue: (id: React.ReactText, fieldName?: string) => void;
};

export const createUseChangesStore = (id: string) =>
	create<CreateUseChangesStore>(
		persist(
			set => ({
				changes: {},
				setChanges: arg0 =>
					set(s => ({
						...s,
						changes: typeof arg0 === "function" ? arg0(s.changes || {}) : arg0,
					})),
				removeValue: (id, fieldName) =>
					set(s => {
						const newState = { ...s.changes };

						if (fieldName) {
							delete newState[id]?.[fieldName];

							if (Object.keys(newState[id] || {}).length === 0) {
								delete newState[id];
							}
						} else {
							delete newState[id];
						}

						return { ...s, changes: newState };
					}),
			}),
			{
				name: id,
			}
		)
	);
