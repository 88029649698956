import { TextField as MuiTextField } from "@mui/material";
import { useField } from "formik";

interface TextFieldProps {
	name: string;
}

const TextFieldBase: React.FC<React.ComponentProps<typeof MuiTextField> & TextFieldProps> = props => {
	return <MuiTextField color="primary" variant="outlined" margin="none" size="small" fullWidth {...props} />;
};

export const TextField = TextFieldBase;

export const TextFieldFormik: React.FC<React.ComponentProps<typeof MuiTextField> & TextFieldProps> = props => {
	const [field, meta] = useField(props.name);
	return <TextFieldBase error={meta.touched && meta.error ? true : false} helperText={meta.touched && meta.error} {...field} {...props} />;
};
