import { makeStyles } from "@mui/styles";

import { EditSvg } from "@assets/icons";

import { renderEditInputCell, DataGrid as MuiDataGrid, GridColumnHeaderParams, GridRenderEditCellParams } from "@mui/x-data-grid";
import { useRef } from "react";
import { Popper } from ".";

const useStyles = makeStyles(() => {
	return {
		root: {
			"& .Mui-error": {
				color: "",
			},
		},
	};
});

export const DataGrid: React.FC<React.ComponentProps<typeof MuiDataGrid>> = ({ localeText, ...props }) => {
	const classes = useStyles();
	return (
		<MuiDataGrid
			className={classes.root}
			rowsPerPageOptions={[10, 25, 50]}
			pageSize={10}
			pagination
			autoHeight
			disableSelectionOnClick
			checkboxSelection
			getRowId={row => row.Id}
			{...props}
			localeText={{
				columnMenuUnsort: 'Sortierung aufheben',
				columnMenuSortAsc: 'Aufsteigend sortieren',
				columnMenuSortDesc: 'Absteigend sortieren',
				columnMenuHideColumn: 'Verstecken',
				columnMenuShowColumns: 'Spalten anzeigen',
				filterPanelColumns: 'Spalten',
				filterPanelOperators: 'Operatoren',
				filterPanelInputLabel: 'Wert',
				filterPanelInputPlaceholder: 'Filterwert',
				footerRowSelected: count => (count !== 1 ? `${count.toLocaleString()} Reihen ausgewählt` : `${count.toLocaleString()} Reihe ausgewählt`),
				...localeText,
			}}
		/>
	);
};

const RenderEditCell: React.FC<GridRenderEditCellParams> = params => {
	const anchorRef = useRef<null | HTMLDivElement>(null);

	const open = Boolean((params as any).error) && Boolean(anchorRef.current);
	const id = open ? "spring-popper" : undefined;

	return (
		<>
			<div className="flex justify-start flex-grow h-full" ref={anchorRef}>
				{renderEditInputCell(params)}
			</div>
			<Popper
				id={id}
				open={open}
				anchorEl={anchorRef.current}
				placement="bottom-start"
			>
				{(params as any).message}
			</Popper>
		</>
	);
};

export const renderEditCell = (params: GridRenderEditCellParams) => {
	return <RenderEditCell {...params} />;
};

export const renderHeader = ({ colDef: { headerName } }: GridColumnHeaderParams) => {
	return (
		<span title="Doppelklick auf die jeweilige Zelle zum Editieren">
			{headerName}
			<EditSvg className="h-3 w-3 inline-block ml-2" />
		</span>
	);
};
