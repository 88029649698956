import { API_URL } from "@admin/config";
import axios, { AxiosRequestConfig } from "axios";

export const defaultQueryFn = async <TData = any>({ queryKey: [key, params] }: any, axiosOptions?: AxiosRequestConfig): Promise<TData> => {
	const queryString = Object.entries((params as Object) || {}).reduce((acc, [key, value]) => {
		const isObjectOrArray = value.constructor.name === "Object" || value.constructor.name === "Array";
		return acc + `&${key}=${encodeURIComponent(isObjectOrArray ? JSON.stringify(value) : value)}`;
	}, "");

	return (
		await axios({
			url: `${API_URL}${key}${queryString}`,
			headers: { "Content-Type": "application/json" },
			...axiosOptions,
		})
	).data;
};
