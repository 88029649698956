import { useMemo } from "react";

import { Card } from "@ui";
import { convert } from "@utils/convert-timestamp-to-string";
import { Total } from "../models";

import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import { twMerge } from "tailwind-merge";

interface StatisticsCardProps {
	color: string;
	label: string;
	sub: string;
	data?: Total[];
}

export const StatisticsCard: React.FC<StatisticsCardProps> = ({ color, label, sub, data }) => {
	const hash = Array.from({ length: 15 }, (v, k) => Math.floor(Math.random() * 10) || 1).join('');

	const avg = useMemo(() => {
		if (!data) return 0;

		const avg = Math.ceil(data.reduce((acc, i) => acc + i.total, 0) / data.length);
		return isNaN(avg) ? 0 : avg;
	}, [data]);

	return (
		<Card>
			<div className="p-3">
				<div>
					<span>{label}</span>
					<span className={twMerge('float-right', color)}>Ø {avg}/Woche</span>
				</div>
				<span className="text-theme-light">{sub}</span>
			</div>
			<ResponsiveContainer width="100%" className="!h-20 lg:!h-28 mt-6">
				<AreaChart
					data={data}
					margin={{
						top: 10,
						right: 0,
						left: 0,
						bottom: 0,
					}}
				>
					<defs>
						<linearGradient id={`color-${hash}`} x1="0" y1="0" x2="0" y2="1" className={color}>
							<stop offset="0%" stopColor={'currentColor'} stopOpacity={0.8} />
							<stop offset="90%" stopColor={'currentColor'} stopOpacity={0.4} />
						</linearGradient>
					</defs>
					<Tooltip content={CustomTooltip} />
					<Area
						type="monotone"
						dot={{ stroke: 'currentColor', r: 4 }}
						dataKey="total"
						stroke={'currentColor'}
						className={color}
						fill={`url(#color-${hash})`}
						animationEasing="ease"
					/>
				</AreaChart>
			</ResponsiveContainer>
		</Card>
	);
};

const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
	const data = payload?.[0]?.payload as Total;
	if (active && data) {
		return (
			<div className="shadow-lg flex flex-col p-2 border border-primary backdrop-blur-2xl bg-theme text-xs">
				<span>Von: {convert(data.startDate, { hour: undefined, minute: undefined })}</span>
				<span>Bis: {convert(data.endDate, { hour: undefined, minute: undefined })}</span>
				<span>Insgesamt: {data.total}</span>
			</div>
		);
	}

	return null;
};