import { ThemeProvider, createTheme } from '@mui/material/styles';
import { deDE } from '@mui/material/locale';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { useSettingsStore } from '@admin/stores/settings';
import { StylesProvider } from '@mui/styles';

export const MuiProvider: React.FC = ({ children }) => {
	const theme = useSettingsStore(s => s.theme);
	return (
		<StyledEngineProvider injectFirst>{/* Emotion */}
			<StylesProvider injectFirst>{/* Legacy: Jss */}
				<ThemeProvider
					theme={createTheme(
						{
							palette: {
								mode: theme,
								primary: {
									main: '#3b82f6'
								},
							},
							zIndex: { snackbar: 0 },
						},
						deDE
					)}
				>
					{children}
				</ThemeProvider>
			</StylesProvider>
		</StyledEngineProvider>
	);
};
