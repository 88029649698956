import React, { useCallback, useRef, useState } from 'react';

import { Permission, useUserStore } from '@stores/user';
import { renderElement } from '@utils/render-element';
import { Popper, Spinner } from '@ui';

import { ButtonBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CSSTransition } from 'react-transition-group';
import { twMerge } from 'tailwind-merge';

export type Sizes    = 'default' | 'small' | 'large';
export type Variants = 'default' | 'outlined' | 'underlined' | 'link';
export type Status   = 'default' | 'success' | 'error' | 'primary';

const svgHover = () => ({
	'& $reactiveIcon': {
		transform: 'translateX(4px)',
		'& .octicon-chevrow-stem': { strokeDashoffset: 20, stroke: '#fff' },
	},
});

const useButtonStyles = makeStyles({
	/* ------------------------------------------- Default ------------------------------------------ */
	root: {
		transition: 'box-shadow 300ms ease, transform 300ms ease, background 200ms ease',
		outline: 'none',

		'&[disabled]': {
			cursor: 'not-allowed',
			pointerEvents: 'auto',
		},
		'&[data-variant^="outlined"]': {
			//boxShadow: "inset 0 0 0 1px var(--tw-shadow-color)",
		},
		'&[data-reactive="true"]': {
			'&:hover': { transform: 'translateY(-2px)', ...svgHover() },
			'&:active': { transform: 'translateY(2px)', ...svgHover() },
		}
	},
	/* ----------------------------------------- Reactivity ----------------------------------------- */
	reactiveIcon: {
		transition: 'transform 300ms',
		'& .octicon-chevrow-right': {
			fill: '#fff',
		},
		'& .octicon-chevrow-stem': {
			stroke: 'transparent',
			strokeDasharray: '10',
			strokeDashoffset: '10',
			transition: 'all 300ms',
		},
	},
	reactiveEffect: {

		// Manipulation
		willChange: 'transform',
		transitionProperty: 'opacity',
		transitionTimingFunction: 'ease',

		// Box-model
		background: 'radial-gradient(circle closest-side, rgba(255, 255, 255, 0.3), transparent)',
		height: '350%',
		width: '350%',

		'&.enter': { opacity: 0 },
		'&.enter-active': { opacity: 1 },
		'&.exit': { opacity: 1 },
		'&.exit-active': { opacity: 0 },
	},
});

type SvgProps = React.SVGProps<SVGSVGElement>;
type SvgElement = React.ReactElement<React.SVGProps<SVGSVGElement>> | React.ComponentType<React.SVGProps<SVGSVGElement>>;

interface ReactiveButtonProps {
	iconEnd?: SvgElement;
	iconProps?: SvgProps;
	iconStart?: SvgElement;
	reactive?: boolean;
	reactiveIcon?: boolean;
	transitionTime?: number;
	size?: Sizes;
	variant?: Variants;
	status?: Status;
	permission?: Permission;
	permissions?: Permission[];
	loading?: boolean;
	loadingElement?: React.ReactNode | React.ReactElement;
}

export const Button: React.FC<React.ComponentProps<typeof ButtonBase> & ReactiveButtonProps> = ({
	children,
	className,
	iconEnd,
	iconProps: { className: iconClassName, ...iconProps } = {},
	iconStart,
	reactive = false,
	reactiveIcon = false,
	transitionTime = 300,
	variant = 'default',
	size = 'default',
	status = 'default',
	permission,
	permissions,
	onMouseMove,
	onMouseEnter,
	onMouseLeave,
	onTouchStart,
	onTouchMove,
	onClick,
	loading,
	loadingElement,
	...props
}) => {
	const classes = useButtonStyles({ reactive });
	let ripple = true;

	let _className = "", _iconClassName = "";
	
	switch (size) {
		case 'small':
			_className += " text-xs";
			_iconClassName += " h-3 w-3 ";
			break;
		case 'large':
			_className += " text-lg";
			break;
	}

	switch (variant) {
		case 'underlined':
			_className = " text-theme hover:underline p-0 overflow-visible border-0 shadow-none bg-transparent";
			ripple = false;
			break;
		case 'link':
			_className += "p-0 underline border-0 shadow-none overflow-visible bg-transparent text-blue-400 underline";
			ripple = false;
			break;
	}

	switch (status) {
		case 'success':
			if (variant === 'default') {
				_className += " bg-green-600 text-white border-green-600";
			}
			break;
		case 'error':
			if (variant === 'default') {
				_className += " bg-red-600 text-white border-red-600";
			}
			break;
		case 'primary':
			if (variant === 'default') {
				_className += " bg-theme-primary text-white";
			}
			break;
		default:
			if (variant === 'default') {
				_className += " border border-solid border-gray-300 dark:border-gray-600";
			}
			break;
	}

	/* ---------------------------------------------------------------------------------------------- */
	const [flashActive, setFlashActive] = useState(false);
	const flashRef = useRef<HTMLDivElement>(null);

	const moveHandler = useCallback((event: React.MouseEvent) => {
		if (!flashRef.current) return;
		const { x, y } = event.currentTarget.getBoundingClientRect();
		const flashPosition = {
			x: event.clientX - x - (flashRef.current.offsetWidth || 0) / 2,
			y: event.clientY - y - (flashRef.current.offsetHeight || 0) / 2,
		};
		flashRef.current.style.transform = `translate(${flashPosition.x}px, ${flashPosition.y}px)`;
	}, []);

	/* ---------------------------------------------------------------------------------------------- */
	const [anchorRef, setAnchorRef] = useState<HTMLButtonElement | null>(null);
	const hasPermission = useUserStore().hasPermission(...(permissions || [permission]))

	return (
		<>
			<ButtonBase
				className={
					twMerge(
						classes.root,
						'inline-flex items-center justify-center flex-row px-4 py-2 h-8 relative bg-theme fill-current will-change-transform cursor-pointer overflow-hidden text-sm rounded-sm shadow-sm font-medium text-theme',
						_className, // Variant/size/status className
						className,  // User className (overrides variant/size/status className)
					)
				}
				onMouseMove={e => {
					moveHandler(e);
					onMouseMove?.(e);
				}}
				onMouseEnter={e => {
					setFlashActive(true);
					onMouseEnter?.(e);
				}}
				onMouseLeave={e => {
					setFlashActive(false);
					onMouseLeave?.(e);
				}}
				onTouchStart={e => {
					e.preventDefault();
					onTouchStart?.(e);
				}}
				onTouchMove={e => {
					e.preventDefault();
					onTouchMove?.(e);
				}}
				onClick={e => {
					hasPermission && onClick?.(e);
				}}
				ref={setAnchorRef}
				{...props}
				disabled={props.disabled || loading}
				data-variant={variant}
				data-size={size}
				data-reactive={reactive}
				disableRipple={!ripple}
			>
				{/* ------------------------------------ Reactive flash effect ----------------------------------- */}
				{reactive ? (
					<CSSTransition
						classNames={{
							enter: 'enter',
							exit: 'exit',
							enterActive: 'enter-active',
							exitActive: 'exit-active',
						}}
						in={flashActive}
						timeout={transitionTime}
						unmountOnExit
					>
						<div className={
							twMerge(
								classes.reactiveEffect,
								'absolute top-0 left-0 select-none pointer-events-none',
							)
						} style={{ transitionDuration: `${transitionTime.toString()}ms` }} ref={flashRef} />
					</CSSTransition>
				) : null}
				{/* ---------------------------------------- Main content ---------------------------------------- */}
				{iconStart ? (
					renderElement(
						iconStart,
						{
							className: twMerge(
								'-ml-1 mr-2 h-4 w-4',
								_iconClassName,
								iconClassName,
								loading ? 'invisible' : ''
							),
							...iconProps
						}
					)
				) : null}
				<span className={["!leading-none", loading ? 'invisible' : ''].join(" ")}>{children}</span>
				{iconEnd ? (
					renderElement(
						iconEnd,
						{
							className: twMerge(
								'ml-2 -mr-1 h-4 w-4',
								_iconClassName,
								iconClassName,
								loading ? 'invisible' : ''
							),
							...iconProps
						}
					)
				) : null}
				{/* ------------------------------------ Reactive icon effect ------------------------------------ */}
				{reactiveIcon ? (
					<div className={["h-0 flex items-center", loading ? 'invisible' : ''].join(" ")}>
						<svg className={classes.reactiveIcon} width="20" height="20" viewBox="0 0 16 16" fill="#fff" xmlns="http://www.w3.org/2000/svg">
							<path
								className="octicon-chevrow-right"
								fill="currentColor"
								d="M7.28033 3.21967C6.98744 2.92678 6.51256 2.92678 6.21967 3.21967C5.92678 3.51256 5.92678 3.98744 6.21967 4.28033L7.28033 3.21967ZM11 8L11.5303 8.53033C11.8232 8.23744 11.8232 7.76256 11.5303 7.46967L11 8ZM6.21967 11.7197C5.92678 12.0126 5.92678 12.4874 6.21967 12.7803C6.51256 13.0732 6.98744 13.0732 7.28033 12.7803L6.21967 11.7197ZM6.21967 4.28033L10.4697 8.53033L11.5303 7.46967L7.28033 3.21967L6.21967 4.28033ZM10.4697 7.46967L6.21967 11.7197L7.28033 12.7803L11.5303 8.53033L10.4697 7.46967Z"
							></path>
							<path className="octicon-chevrow-stem" stroke="currentColor" d="M1.75 8H11" strokeWidth="1.5" strokeLinecap="round"></path>
						</svg>
					</div>
				) : null}
				{/* ------------------------------------------- Loading ------------------------------------------ */}
				{!!loading && (
					<div className="absolute flex items-center gap-2 transform -translate-x-1/2 -translate-y-1/2 flex-nowrap top-1/2 left-1/2">
						{loadingElement ? renderElement(loadingElement) : <Spinner size={'small'} />}
					</div>
				)}
			</ButtonBase>
			<Popper open={flashActive && !hasPermission} anchorEl={anchorRef}>
				Fehlende {permissions ? 'Berechtigungen' : 'Berechtigung'}! <span className="text-neutral-400">({permissions ? permissions.join(", ") : permission})</span>
			</Popper>
		</>
	);
};
