import { BrowserRouter, Navigate, Route, Routes as BrowserRoutes } from 'react-router-dom';
import { FullScreenNotification } from '@ui';
import { ProtectedRoute } from './ProtectedRoute';

import { AppsPage } from '@pages/apps';
import { IPv4Page } from '@pages/bot-protection/IPv4';
import { UserAgentPage } from '@pages/bot-protection/UserAgent';
import { MainPage } from '@pages/main';
import { ServerPage } from '@pages/server';
import { LoginPage } from '@pages/login';

// DashboardLayout has to be defined after the pages. Otherwise the error
// 'DashboardLayout is used before it's initialization.' is thrown.
import { DashboardLayout } from '@ui/layouts/DashboardLayout';
import { DumpsPage } from '@admin/pages/dumps';

export const Routes = () => {
	return (
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<BrowserRoutes>
				<Route path="dashboard" element={
					<ProtectedRoute>
						<DashboardLayout />
					</ProtectedRoute>
				}>
					<Route index element={<MainPage />} />
					<Route path="server">
						<Route index element={<ServerPage />} />
						<Route path=":id" element={<AppsPage />} />
					</Route>
					<Route path="applications" element={<AppsPage />} />
					<Route path="bot-protection">
						<Route path="ip" element={<IPv4Page />} />
						<Route path="ua" element={<UserAgentPage />} />
					</Route>
					<Route path="dumps/*" element={<DumpsPage />} />
				</Route>
				<Route path="login" element={<LoginPage />} />
				<Route index element={<Navigate to="/dashboard" />} />
				<Route path="*" element={<FullScreenNotification variant="404" />} />
			</BrowserRoutes>
		</BrowserRouter>
	)
}