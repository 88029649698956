import { Multistep, Step } from '@ui';
import { useQuery } from '@admin/libs/react-query';
import { Dialog, DialogContent, Divider } from '@mui/material';
import { useContext } from 'react';
import { appContext } from '../context';
import { DialogProps, Missing } from '../models';
import { LoadingStep } from './LoadingStep';
import { SuccessDialogContent } from './SuccessDialogContent';

interface MissingDialogProps {}

export const MissingDialog: React.FC<MissingDialogProps & DialogProps> = ({ onClose, open }) => {
	const { env, app } = useContext(appContext);
	const { data, isError } = useQuery<Missing[]>(`missing&appid=${app.APPID}&environment=${env}`, { enabled: open });

	if (isError) {
		onClose();
	}

	const serverWithMissingTks = data?.filter(item => item.missingtks.length > 0);

	return (
		<Dialog onClose={onClose} open={open}>
			<Multistep step={data ? 1 : 0}>
				{LoadingStep}
				<Step>
					{serverWithMissingTks?.length ? (
						<DialogContent className="w-[500px] space-y-4">
							{serverWithMissingTks.map(({ server, missingtks }, index) => (
								<>
									<div>
										<span className="font-bold">{server}</span>:{' '}
										<span className={`${missingtks.length === 0 ? 'text-green-500' : 'text-red-500'}`}>
											{missingtks.length} fehlende Textkostanten gefunden {missingtks.length === 0 ? '✔' : '✖'}
										</span>
										<ul className="list-decimal px-8 mt-2 text-base">
											{missingtks.map(tk => (
												<li key={tk}>{tk}</li>
											))}
										</ul>
									</div>
									{index !== serverWithMissingTks!.length - 1 && <Divider />}
								</>
							))}
						</DialogContent>
					) : (
						<SuccessDialogContent title="Keine fehlenden Textkonstanten!" message="Es wurden keine fehlenden Textkonstanten auf allen Servern gefunden!" />
					)}
				</Step>
			</Multistep>
		</Dialog>
	);
};
