import { useUserStore } from '@admin/stores/user';
import { Skeleton, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BsFillExclamationOctagonFill } from 'react-icons/bs';
import { useQuery } from 'react-query';
import { ListOnItemsRenderedProps } from 'react-window';
import { InnerRoutes } from './components/InnerRoutes';
import { VirtualizedList } from './components/VirtualizedList';
import { ErrorBase } from './models';

{
	/*<div className="self-center hidden text-center">
		<svg className="w-12 h-12 mx-auto text-theme-lighter" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
			<path
				vectorEffect="non-scaling-stroke"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
				d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
			/>
		</svg>
		<h4 className="mt-2 font-medium">Keine Fehler / Dumps vorhanden</h4>
		<p className="mt-1 text-sm text-theme-light">Beginnen Sie mit dem Einrichten von Dumps in Ihrer lokalen Devumgebung.</p>
		<div className="mt-6">
			<Button status="primary" iconEnd={<ArrowLongRightSvg />}>
				Anleitung
			</Button>
		</div>
	</div>*/
}

export const DumpsPage = () => {
	const { env } = useUserStore(s => s.user) || {};
	const [itemsInView, setItemsInView] = useState<[number, number]>([0, 0]);
	const [query, setQuery] = useState('');

	const data =
		useQuery<ErrorBase[][]>(['errors', { environment: env }], { refetchInterval: 5 * 1000 }).data
			?.flat()
			?.reverse();

	const handleItemsRendered = useCallback(
		(props: ListOnItemsRenderedProps) => {
			setItemsInView([props.visibleStartIndex, props.visibleStopIndex]);
		},
		[setItemsInView]
	);

	useEffect(() => {
		toast.custom(t => (
			<div className={`bg-yellow-50 max-w-sm p-4 ${t.visible ? 'animate-enter' : 'animate-leave'}`}>
				<div className="flex">
					<div className="flex-shrink-0">
						<BsFillExclamationOctagonFill className="h-5 w-5 text-yellow-400" aria-hidden="true" />
					</div>
					<div className="ml-3">
						<h3 className="text-sm font-medium text-yellow-800">Achtung</h3>
						<div className="mt-2 text-sm text-yellow-700">
							<p>
								Diese Seite befindet sich derzeit aktiv in der Entwicklung. Es können Fehler auftreten und nicht alle Funktionen verfügbar sein.
							</p>
						</div>
					</div>
				</div>
			</div>
		))
	}, []);

	return (
		<div className="grid h-full grid-cols-[20rem_1fr]">
			<div className="relative flex-grow">
				{/*<div className="sticky inset-x-0 top-14 md:top-16 h-[calc(100vh_-_4rem)] flex flex-col border-r bg-theme border-r-theme-divider shadow-sidebar">*/}
				<div className="sticky inset-x-0 top-14 md:top-16 h-[calc(100vh_-_4rem)] overflow-y-auto flex flex-col border-r bg-theme border-r-theme-divider shadow-sidebar">
					<div className="flex flex-col flex-grow">
						<div className="p-4">
							<TextField className="w-full mb-2" placeholder="Betreff, IP-Adresse, User Agent, ..." variant="standard" onChange={e => setQuery(e.target.value)} />
							{data ? (
								itemsInView[0] !== 0 ? (
									<p className="text-sm text-theme-light">
										{itemsInView[0] + 1} bis {itemsInView[1] + 1} von {data.length} Ergebnissen
									</p>
								) : (
									<p className="text-sm text-theme-light">Insgesamt {data.length || 0} Ergebnisse in 7 Tagen</p>
								)
							) : (
								<Skeleton height={16} width={100} variant="text" />
							)}
						</div>
						<VirtualizedList data={data} query={query} onItemsRendered={handleItemsRendered} />
					</div>
				</div>
			</div>
			<InnerRoutes />
		</div>
	);
};
