import { MessageCircleSvg, UserSvg, MenuSvg, MoonSvg, SunSvg } from '@assets/icons';
import { Popper, PopperVariants } from '@ui';
import { useUserStore } from '@stores/user';
import { ClickAwayListener, Grow, IconButton, ListItemIcon, ListItemText, MenuItem, MenuList, Paper } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useLocalStorage } from '@hooks/use-local-storage';

import TestingIcon from '@assets/icons/png/icons8-testing-64.png';
import { useSettingsStore } from '@admin/stores/settings';

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
	const theme = useSettingsStore(s => s.theme);
	const { user: { userId, username, fullName, env } = {} } = useUserStore();
	const anchorRef = useRef<HTMLDivElement>(null);
	const [popperOpen, setPopperOpen] = useState(false);

	const isUser = !!(userId !== 0);

	let renderEnvironment = null;

	useEffect(() => {
		if (theme === 'dark') {
			document.documentElement.classList.add('dark');
		} else {
			document.documentElement.classList.remove('dark');
		}
	}, [theme]);

	switch (env) {
		case 'dev':
			renderEnvironment = (
				<>
					{/*<img src={DevelopmentIcon} alt="development" className="h-8 w-8" />*/}
					<span className="text-theme">Entwicklungsumgebung</span>
				</>
			);
			break;
		case 'test':
			renderEnvironment = (
				<>
					<img src={TestingIcon} alt="testing" className="h-8 w-8" />
					<span className="text-orange-500">Testumgebung</span>
				</>
			);
			break;
		case 'live':
			renderEnvironment = (
				<>
					<span className="text-red-700 font-bold underline">Live-Umgebung</span>
				</>
			);
			break;
		case 'stage':
			renderEnvironment = (
				<>
					<img src={TestingIcon} alt="staging" className="h-8 w-8" />
					<span className="text-orange-500">Staging-Umgebung</span>
				</>
			);
			break;
	}

	return (
		<div className="h-14 md:h-16">
			<header className="flex flex-shrink-0 items-center h-14 md:h-16 z-10 md:left-12 lg:left-60 px-4 bg-theme-navbar border-b border-theme-divider fixed inset-0 " role="banner">
				<div className="hidden md:flex gap-2 items-center">{renderEnvironment}</div>
				<div className="block md:hidden">
					<button type="button" className="-ml-2 h-12 w-12 inline-flex items-center justify-center rounded-md text-theme-light hover:text-theme">
						<span className="sr-only">Open sidebar</span>
						<MenuSvg className="h-6 w-6" aria-hidden="true" />
					</button>
				</div>
				<div className="flex-1"></div>
				<IconButton onClick={() => useSettingsStore.setState({ theme: theme === 'light' ? 'dark' : 'light' })}>
					{theme === 'dark' ? <MoonSvg className="h-4 w-4" /> : <SunSvg className="h-4 w-4" />}
				</IconButton>
				<div className={`flex items-center justify-center gap-2 self-center ml-2`}>
					<span className="hidden lg:inline-block underline underline-offset-2">{isUser ? fullName || username || 'Entwickler' : 'Entwickler'}</span>
					<div className="rounded-full h-9 w-9">
						<img
							src={`https://avatars.dicebear.com/api/bottts/${isUser ? username || fullName || 'Entwickler' : 'Entwickler'}.svg?b=%233b82f6&r=50`}
							alt="avatar"
						/>
					</div>
				</div>
				{isUser && (
					<Popper className="z-20" variant={PopperVariants.profileScreen} open={popperOpen} anchorEl={anchorRef.current} transition disablePortal>
						{({ TransitionProps, arrow }: any) => (
							<Grow
								{...TransitionProps}
								style={{
									transformOrigin: 'top',
								}}
							>
								<Paper sx={{ width: 240, maxWidth: '100%' }} elevation={3}>
									{arrow}
									<ClickAwayListener onClickAway={setPopperOpen.bind(null, false)}>
										<MenuList autoFocusItem={popperOpen} id="profile-menu" aria-labelledby="profile-menu-list" dense>
											{[
												[UserSvg, 'Einstellungen'],
												[MessageCircleSvg, 'Nachrichten'],
											].map(([Icon, label]) => (
												<MenuItem disabled>
													<ListItemIcon>
														<Icon className="h-5 w-5" />
													</ListItemIcon>
													<ListItemText>{label}</ListItemText>
												</MenuItem>
											))}
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				)}
			</header>
		</div>
	);
};
