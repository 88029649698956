import { Player } from '@lottiefiles/react-lottie-player';
import { DialogContent } from '@mui/material';
import { useMemo } from 'react';
import successAnimationData1 from '@assets/lottie-animation-data/success-animation-data-1.json';
import successAnimationData2 from '@assets/lottie-animation-data/success-animation-data-2.json';
import successAnimationData4 from '@assets/lottie-animation-data/success-animation-data-4.json';

const player = [
	<Player src={successAnimationData1} keepLastFrame autoplay className="max-w-[200px]" />,
	<Player src={successAnimationData2} keepLastFrame autoplay className="max-w-[200px]" />,
	<Player src={successAnimationData4} keepLastFrame autoplay className="max-w-[200px] !-mb-4" />,
]

interface SuccessDialogContentProps {
	title: string;
	message: string;
}

export function SuccessDialogContent({ title, message }: SuccessDialogContentProps) {
	const _player = useMemo(() => player[Math.floor(Math.random() * player.length)], []);

	return (
		<DialogContent className="w-[325px] space-y-4 text-center px-6 py-10">
			<div className="space-y-1">
				<h3>{title}</h3>
				<p className="text-theme-light text-light">{message}</p>
			</div>
			{_player}
		</DialogContent>
	);
}
