import React from "react";

export function renderElement<TElement extends React.ReactNode | React.ElementType>(
	element?: TElement,
	props?: TElement extends React.ElementType ? React.ComponentProps<TElement> : never
): React.ReactNode {
	if (React.isValidElement(element)) {
		return React.cloneElement(element, props);
	}

	if (typeof element === 'function') {
		return React.createElement(element as React.FunctionComponent, props);
	}

	return element;
}