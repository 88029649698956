import { Spinner, Step } from "@ui";
import { Stack } from "@mui/material";

export const LoadingStep = (
	<Step hideHeader hideFooter>
		<div className="relative">
			<Stack direction="row" justifyContent="center" alignItems="center" gap={4} maxWidth={360} padding={4}>
				<Spinner variant="rocket" />
				<p>Unsere Datenkapsel ist bereits auf dem Weg zum Mond und sollte in kürze landen!</p>
			</Stack>
		</div>
	</Step>
)