import { InfoSvg } from '@assets/icons';
import {
	Multistep,
	Step, Table, TableDetails, TableDetailsRow, TableTitle
} from '@ui';
import { useQuery } from '@admin/libs/react-query';
import { Dialog, DialogContent, MenuItem, Select } from '@mui/material';
import { useContext, useState } from 'react';
import { appContext } from '../context';
import { DialogProps, Messages } from '../models';
import { LoadingStep } from './LoadingStep';
import { SuccessDialogContent } from './SuccessDialogContent';

interface MessagesDialogProps {}

export const MessagesDialog: React.FC<MessagesDialogProps & DialogProps> = ({ onClose, open }) => {
	const { env, app } = useContext(appContext);
	const { data, isError } = useQuery<Messages[]>(`messages&appid=${app.APPID}&environment=${env}`, { enabled: open });
	const [selectedServer, setSelectedServer] = useState(0);
	const [selectedMessage, setSelectedMessage] = useState(0);

	if (isError) {
		onClose();
	}

	const serverWithMessages = data?.filter(item => item.messages.length > 0);

	return (
		<Dialog onClose={onClose} open={open}>
			<Multistep step={data ? 1 : 0}>
				{LoadingStep}
				<Step>
					{serverWithMessages?.length ? (
						<DialogContent className="space-y-4">
							<label className="block">
								Server
								<Select value={selectedServer} onChange={e => setSelectedServer(Number(e.target.value))} className="w-full" size="small">
									{serverWithMessages.map(({ server }, index) => (
										<MenuItem key={index} value={index}>
											{server}
										</MenuItem>
									))}
								</Select>
							</label>
							<label className="block">
								Nachricht
								<Select value={selectedMessage} onChange={e => setSelectedMessage(Number(e.target.value))} className="w-full" size="small">
									{serverWithMessages[selectedServer].messages.map((message, index) => (
										<MenuItem key={message.subject} value={index}>
											{message.subject}
										</MenuItem>
									))}
								</Select>
							</label>
							{serverWithMessages[selectedServer].messages[selectedMessage].debug?.map(item => (
								<Table className="mb-6">
									<TableTitle icon={InfoSvg}>{item.name}</TableTitle>
									<TableDetails>
										{Object.entries(item.value || (item as any).VALUE || {}).map(([key, value]) => (
											<TableDetailsRow title={key} details={value} />
										))}
									</TableDetails>
								</Table>
							))}
						</DialogContent>
					) : (
						<SuccessDialogContent title="Keine Nachrichten!" message="Es wurden keine Nachrichten auf allen Servern gefunden!" />
					)}
				</Step>
			</Multistep>
		</Dialog>
	)
};
