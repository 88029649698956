import { API_URL } from '@admin/config';
import { useMutation, usePageQuery, useQueryClient } from '@admin/libs/react-query';
import { CheckSvg, PlusSvg, RefreshSvg, Trash2Svg } from '@assets/icons';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useSettingsStore } from '@stores/settings';
import { Permission, useUserStore } from '@stores/user';
import { Button, Card, CardBody, DataGrid } from '@ui';
import { debounce } from '@utils/debounce';
import { AddModalProps, Bots, Ips, Urls } from "../models";
import { createUseChangesStore } from './changes-store';

import { GridCellEditCommitParams, GridColDef, GridLocaleText, GridRowsProp } from '@mui/x-data-grid';
import axios from 'axios';
import { diffChars } from 'diff';
import { AnimatePresence, motion } from 'framer-motion';
import { ForwardedRef, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

export interface PageBaseProps {
	id: string;
	addModal: React.FC<AddModalProps>;
	urls: Urls;
	editPermission: Permission;
	columns: (rows: GridRowsProp) => GridColDef[];
	dataGridlocaleText: Partial<GridLocaleText> & {
		noRowsLabel: string;
	};
	localeText: {
		cardTitle: string;
		changesSnackbarTitle: (count: number) => React.ReactNode;
		addModalTitle: string;
		addModalAlreadyExists: string;
	};
	editButtonExtra?: (buttons: React.ReactElement[]) => void;
}

export function PageBase<TItems extends Bots | Ips>(
	{ id, editPermission, addModal: AddModal, urls, columns, dataGridlocaleText, editButtonExtra }: PageBaseProps,
	ref: ForwardedRef<HTMLDivElement>
) {
	const useChangesStore = useMemo(() => createUseChangesStore(id), [id]);
	const { removeValue, setChanges, changes } = useChangesStore();

	const [selected, setSelected] = useState<React.ReactText[]>([]);
	const [modals, setModals] = useState({
		remove: { open: false },
		add: { open: false },
		changes: { open: false },
	});

	const queryClient = useQueryClient();

	// Refetch every 60 seconds to keep in sync with different users.
	// No websockets because not possible to implement with ColdFusion.
	const { data: items } = usePageQuery<TItems>(urls.listUrl, { refetchInterval: 60 * 1000 });
	const { mutateAsync: addItem } = useMutation(values => axios.post(API_URL + urls.addUrl, values), { onError: async () => null });
	const { mutateAsync: removeItems } = useMutation(() => axios.post(API_URL + urls.removeUrl, selected), { onError: async () => null });

	const itemsChangesMerged = useMemo(() => items?.map(i => ({ ...i, ...changes[i.Id] })) || [], [items, changes]);

	const toggleModal = useCallback(
		(key: keyof typeof modals, open: boolean) => {
			setModals(s => ({ ...s, [key]: { ...s[key], open } }));
		},
		[setModals]
	);

	/**
	 * This is triggered when the "Add" modal is confirmed
	 *
	 * 	* Sends the new entry when confirming to the server
	 * 	* Updates the cache on successful submission
	 */
	const handleAddModalSubmit = useCallback(
		async (values: any) => {
			toggleModal('add', false);

			const toastId = toast.loading('Der Eintrag wird hinzugefügt...');

			try {
				const { data } = await debounce(addItem(values));

				if (data.error) {
					if (data.error.type === 'ERR_ALREADY_EXISTS') {
						toast.error('Ohh, ein solcher Eintrag existiert bereits! 👀', {
							id: toastId,
						});
						return;
					} else {
						throw new Error();
					}
				}

				toast.success('Der Eintrag wurde hinzugefügt 🥳', {
					id: toastId,
				});

				const { fullName = 'Entwickler' } = useUserStore.getState().user || {};

				queryClient.setQueryData(urls.listUrl, (prev: any) => [
					...(prev || []),
					{
						CreatedUserTitle: fullName,
						UpdatedUserTitle: fullName,
						...data,
					},
				]);

				useSettingsStore.getState().setShouldReloadBotProtectionHintActive();
			} catch (error) {
				toast.error('Ups 🤢 ein Fehler ist aufgetreten!', {
					id: toastId,
				});
			}
		},
		[queryClient, addItem, toggleModal, urls.listUrl]
	);

	/**
	 * This is triggered when the "delete" modal is confirmed
	 *
	 * * Send the entry to be deleted when confirming to the server
	 * * Update the cache on successful submission
	 */
	const handleRemoveSelection = useCallback(
		async () => {
			const toastId = toast.loading('Okay... die Einträge werden gelöscht 😥');
			toggleModal('remove', false);

			try {
				const data = await debounce(removeItems());

				if (data) {
					queryClient.setQueryData(urls.listUrl, (data: any) => (data || []).filter((i: any) => !selected.includes(i.Id)));
					selected.forEach(i => removeValue(i));
					setSelected([]);
				}

				toast.success('Alles klar, sie werden dich nicht mehr belästigen ✔', {
					id: toastId,
				});

				useSettingsStore.getState().setShouldReloadBotProtectionHintActive();
			} catch (error) {
				toast.error('Ups, ein Fehler ist aufgetreten! 🤢', {
					id: toastId,
				});
			}
		},
		[queryClient, removeItems, removeValue, selected, toggleModal, urls.listUrl]
	);

	const handleEditCellChangeCommitted = useCallback(
		({ id, value, field }: GridCellEditCommitParams) => {
			const item = (items as any[])?.find((i: any) => i.Id === id);

			if ((value as string).trim() === '') {
				return setChanges(s => s);
			}

			if (item?.[field] === value) {
				return removeValue(id, field);
			}

			setChanges((s: any) => ({
				...s,
				[id]: {
					...s?.[id],
					[field]: value,
				},
			}));
		},
		[items, setChanges, removeValue]
	);

	useLayoutEffect(() => {
		editButtonExtra?.([
			<Button
				key="remove"
				iconStart={<Trash2Svg />}
				status="error"
				className={`text-white ${selected.length === 0 ? `bg-red-400 border-red-400` : ''}`}
				disabled={!selected.length}
				onClick={toggleModal.bind(null, 'remove', true)}
				permission={editPermission}
			>
				Löschen
			</Button>,
			<Button key="add" iconStart={<PlusSvg />} onClick={toggleModal.bind(null, 'add', true)} permission={editPermission}>
				Neu
			</Button>,
		]);
	}, [editPermission, selected, toggleModal, editButtonExtra]);

	const originalData = queryClient.getQueryData(urls.listUrl) as TItems;
	const renderChanges = useMemo(
		() => (
			<ul className="text-theme-light list-disc pl-6">
				{Object.entries(changes).map(([index, change]) => {
					let renderChange: React.ReactNode[] = [];

					for (const key in change) {
						// Typescript error of union typed arrays not working correctly
						// => https://stackoverflow.com/a/62296144/15927898
						const from = (originalData as any[]).find(i => i.Id === Number(index))?.[key] || '';
						const to = change[key];

						diffChars(from, to).forEach(part => {
							const className = part.added ? 'text-green-600' : part.removed ? 'text-red-600' : '';
							renderChange.push(
								<span key={index + part.value} className={className}>
									{part.value}
								</span>
							);
						});
					}

					return (
						<li className="text-theme-light" key={index}>
							{renderChange}
						</li>
					);
				})}
			</ul>
		),
		[changes, originalData]
	);

	const mUpdates = useMutation(() => axios.post(API_URL + urls.updateUrl, changes));
	const saveChangesloading = mUpdates.isLoading || mUpdates.isSuccess;

	const mutateCache = useCallback(
		(data: any) => {
			queryClient.setQueryData(urls.listUrl, (oldData: any) =>
				(oldData || []).map((i: any) => ({
					...i,
					...changes[i.Id],
					...(Array.isArray(data) && data.find((item: any) => i.Id === item.Id)),
				}))
			);
		},
		[changes, queryClient, urls.listUrl]
	);

	const handleSaveChanges = useCallback(async () => {
		try {
			const { data } = await debounce(mUpdates.mutateAsync());

			if (data) {
				mutateCache(data);
				setChanges({});
			}

			// Reset the mutation state so that the changes bar is in its initial
			// state when new changes are made.
			mUpdates.reset();

			useSettingsStore.getState().setShouldReloadBotProtectionHintActive();
		} catch {}
	}, [mUpdates, mutateCache, setChanges]);

	const changesLength = Object.keys(changes).length;

	return (
		<>
			<Card>
				<CardBody>
					<DataGrid
						selectionModel={selected}
						onSelectionModelChange={setSelected}
						rows={itemsChangesMerged}
						onCellEditCommit={handleEditCellChangeCommitted}
						columns={columns(itemsChangesMerged)}
						localeText={dataGridlocaleText}
						rowsPerPageOptions={[50, 100, 200]}
						pageSize={50}
						density="compact"
						initialState={{
							sorting: {
								sortModel: [{ field: 'UpdatedDate', sort: 'desc' }],
							},
						}}
					/>
				</CardBody>
			</Card>
			<Dialog open={modals.remove.open} onClose={toggleModal.bind(null, 'remove', false)}>
				<DialogContent className="sm:max-w-lg sm:w-full">
					<div className="sm:flex sm:items-start">
						<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
							<svg
								className="h-6 w-6 text-red-600"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="2"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
								/>
							</svg>
						</div>
						<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
							<h3 className="text-lg leading-6 font-medium text-theme" id="modal-title">
								{selected.length === 1 ? 'Datensatz' : 'Datensätze'} löschen
							</h3>
							<div className="mt-2">
								<p className="text-sm text-theme-light">
									Sind Sie sicher, dass Sie {selected.length === 1 ? 'den Eintrag' : 'die Einträge'} löschen möchten? Alle Daten werden dauerhaft entfernt.
									Diese Aktion kann nicht rückgängig gemacht werden.
								</p>
							</div>
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={toggleModal.bind(null, 'remove', false)}>Abbrechen</Button>
					<Button onClick={handleRemoveSelection} status="error">
						Löschen
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={modals.add.open} onClose={toggleModal.bind(null, 'add', false)}>
				<div className="sm:max-w-[26rem] sm:w-[26rem]">
					<AddModal handleSubmit={handleAddModalSubmit} onClose={toggleModal.bind(null, 'add', false)} />
				</div>
			</Dialog>
			<AnimatePresence>
				{changesLength > 0 ? (
					<motion.div
						className="!mt-0"
						initial={{ height: 0 }}
						animate={{ height: '4rem' }}
						exit={{ height: 0 }}
					>
						<motion.div
							className="fixed left-60 bottom-0 right-0 h-16 bg-theme border-t border-theme-divider"
							initial={{ opacity: 0, y: '100%' }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: '100%' }}
						>
							<div className="mx-auto max-w-7xl sm:px-32 px-4 flex-grow  flex items-center justify-between h-16">
								<div className="flex items-center">
									Ungespeicherte {Object.keys(changes).length === 1 ? 'Änderung' : 'Änderungen'} ({Object.keys(changes).length}){' '}
									<Button className="ml-4" variant="link" onClick={toggleModal.bind(null, 'changes', true)}>
										Anzeigen
									</Button>
								</div>

								<div className="space-x-2">
									<Button
										iconStart={<RefreshSvg />}
										// Refresh icon is bigger than the other icons
										iconProps={{ className: 'h-3 w-3' }}
										onClick={setChanges.bind(null, () => ({}))}
									>
										Zurücksetzen
									</Button>
									<Button iconStart={<CheckSvg />} status="success" onClick={handleSaveChanges} loading={saveChangesloading}>
										Speichern
									</Button>
								</div>
							</div>
						</motion.div>
					</motion.div>
				) : null}
			</AnimatePresence>
			<Dialog open={modals.changes.open} onClose={toggleModal.bind(null, 'changes', false)}>
				<DialogTitle>Änderungen</DialogTitle>
				<DialogContent className="sm:max-w-[24rem] sm:w-[24rem]">{renderChanges}</DialogContent>
				<DialogActions>
					<Button onClick={toggleModal.bind(null, 'changes', false)}>Schließen</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}