import React, { Suspense } from "react";

import { FullScreenNotification, Spinner } from "@ui";
import { useUserStore } from "@stores/user";
import { Header } from ".";
import { Sidebar } from "./Sidebar";

import { AxiosError } from "axios";
import { ErrorBoundary } from "react-error-boundary";
import { QueryErrorResetBoundary } from "react-query";
import { Outlet } from "react-router-dom";

interface DashboardLayoutProps {}

export const DashboardLayout: React.FC<DashboardLayoutProps> = () => {
	function handleError(error: AxiosError) {
		if (error?.response?.status === 401) {
			useUserStore.getState().logout();
		}
	}

	return (
		<>
			{/*<Navbar />*/}
			<Sidebar />

			<div className="max-w-full md:pl-12 lg:pl-60 flex-1 flex flex-col">
				<Header />
				<QueryErrorResetBoundary>
					{({ reset }) => (
						<ErrorBoundary
							onError={(error: Error) => handleError(error as AxiosError)}
							fallbackRender={({ resetErrorBoundary }) => <FullScreenNotification variant="error" resetErrorBoundary={resetErrorBoundary} />}
							onReset={reset}
						>
							<Suspense
								fallback={
									<div className="h-full grid place-items-center">
										<Spinner variant="rocket" />
									</div>
								}
							>
								<main className="flex-1">
									<Outlet />
								</main>
							</Suspense>
						</ErrorBoundary>
					)}
				</QueryErrorResetBoundary>
			</div>
		</>
	);
};
