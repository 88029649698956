import { CheckSvg, ShieldSvg } from '@assets/icons';
import { Button, renderEditCell, renderHeader, TextFieldFormik } from '@ui';
import { Container } from '@ui/layouts';
import { Permission, useUserStore } from '@stores/user';
import { convert } from '@utils/convert-timestamp-to-string';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { object, string } from 'yup';
import { PageBase } from './components/PageBase';
import { dateComparator } from "./components/date-comparator";
import { Ips, Urls, AddModalProps } from "./models";

export const ipValidation = string()
	.trim()
	.matches(
		/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])|\*)\.(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])|\*)\.(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])|\*)\.(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])|\*)$/g,
		'Bitte geben Sie eine gültige IPv4-Adresse ein!'
	)
	.required('Bitte füllen Sie dieses Feld aus!');

const urls: Urls = {
	listUrl: 'listIps',
	removeUrl: 'removeIps',
	updateUrl: 'updateIps',
	addUrl: 'addIp',
};

const AddModal: React.FC<AddModalProps> = ({ handleSubmit, onClose }) => {
	return (
		<Formik
			initialValues={{
				IPAddress: '',
			}}
			validationSchema={object({
				IPAddress: ipValidation,
			})}
			onSubmit={handleSubmit}
		>
			<Form>
				<DialogTitle>
					Neue IPv4 Adresse
				</DialogTitle>
				<DialogContent>
					<TextFieldFormik label="IP-Adresse (Beispiel: 255.255.13.37)" name="IPAddress" />
					<em className="text-theme-light text-sm mt-4 inline-block">
						<span className="font-bold">Hinweis: </span>
						Nutzen Sie das Asterisk-Symbol (*), um an der entsprechenden Stelle einen Bereich von 0 - 255 abzudecken.
					</em>
				</DialogContent>
				<DialogActions>
					<Button type="button" onClick={onClose}>
						Abbrechen
					</Button>
					<Button type="submit" status="success" iconStart={<CheckSvg />}>
						Hinzufügen
					</Button>
				</DialogActions>
			</Form>
		</Formik>
	);
};

interface IPv4PageProps {}

export const IPv4Page: React.FC<IPv4PageProps> = () => {
	const [buttonExtra, setButtonExtra] = useState<React.ReactElement[]>([]);

	return (
		<Container
			icon={ShieldSvg}
			title={
				<>
					Bot-Schutz - <span className="font-semibold">IPv4</span>
				</>
			}
			extra={buttonExtra}
		>
			<PageBase<Ips>
				id="__b_ip"
				addModal={AddModal}
				urls={urls}
				editPermission={Permission.EDIT_IPV4}
				columns={rows => [
					{
						field: 'IPAddress',
						headerName: 'IP-Adresse',
						width: 300,
						editable: true,
						renderEditCell,
						renderHeader,
						preProcessEditCellProps: params => {
							const {
								props: { value },
								id,
							} = params;
							let message = '';

							if (rows.find(i => i.ipAddress === value && i.id !== id)) {
								message = 'Es ist bereits ein Eintrag vorhanden mit dieser IP-Adresse';
							}

							try {
								ipValidation.validateSync(value);
							} catch (err: any) {
								message = err.message;
							}

							if (!useUserStore.getState().hasPermission(Permission.EDIT_IPV4)) {
								message = 'Fehlende Berechtigung zum Editieren der Einträge!';
							}

							return { ...params.props, error: !!message, message };
						},
					},
					{ field: 'UpdatedUserTitle', headerName: 'Letzter Bearbeiter', width: 200, renderCell: (params: GridRenderCellParams) => params.value || 'Entwickler' },
					{ field: 'CreatedUserTitle', headerName: 'Ersteller', width: 200, renderCell: (params: GridRenderCellParams) => params.value || 'Entwickler' },
					{ field: 'UpdatedDate', headerName: 'Zuletzt bearbeitet', width: 250, renderCell: (params: GridRenderCellParams) => convert(params.value), sortComparator: dateComparator },
					{ field: 'CreatedDate', headerName: 'Hinzugefügt', width: 250, renderCell: (params: GridRenderCellParams) => convert(params.value), sortComparator: dateComparator },
				]}
				dataGridlocaleText={{
					noRowsLabel: 'Keine IP-Adressen verfügbar',
				}}
				localeText={{
					cardTitle: 'Blockierte IP-Adressen',
					changesSnackbarTitle: count => `Sie haben ${count} ungespeicherte ${count === 1 ? 'Änderung' : 'Änderungen'} in der IP-Liste`,
					addModalTitle: 'IP-Adresse hinzufügen',
					addModalAlreadyExists: 'Es ist bereits ein Eintrag mit dieser IP-Adresse vorhanden!',
				}}
				editButtonExtra={setButtonExtra}
			/>
		</Container>
	);
};