import {
	Accordion as MuiAccordion,
	AccordionDetails as MuiAccordionDetails,
	AccordionSummary as MuiAccordionSummary,
	Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ArrowDownSignToNavigateSvg } from "@assets/icons";
import Highlight, { defaultProps } from "prism-react-renderer";
import themeLight from "prism-react-renderer/themes/github";
import themeDark from "prism-react-renderer/themes/vsDark";
import { useSettingsStore } from "@admin/stores/settings";

const useAccordionStyles = makeStyles({
	root: {
		transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		"&.Mui-expanded": {
			//border: `1px solid ${colors.gray[700]}`,
			//boxShadow: "none",
		},
	},
});

export const Accordion: React.FC<React.ComponentProps<typeof MuiAccordion>> = ({ className, children, ...props }) => {
	const classes = useAccordionStyles();
	return (
		<MuiAccordion className={`${className || ""}`} classes={classes} {...props}>
			{children}
		</MuiAccordion>
	);
};

const useAccordionSummaryStyles = makeStyles({
	root: {
		transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		"&.Mui-expanded": {
			//borderBottom: `1px solid ${colors.gray[700]}`,
		},
	},
});

export const AccordionSummary: React.FC<React.ComponentProps<typeof MuiAccordionSummary>> = ({ className, children, ...props }) => {
	const classes = useAccordionSummaryStyles();
	return (
		<MuiAccordionSummary
			className={`${className || ""}`}
			classes={classes}
			expandIcon={<ArrowDownSignToNavigateSvg className="h-4 w-4 fill-current text-black" />}
			{...props}
		>
			{children}
		</MuiAccordionSummary>
	);
};

export const AccordionDetails: React.FC<React.ComponentProps<typeof MuiAccordionDetails>> = ({ className, children, ...props }) => {
	return (
		<MuiAccordionDetails className={`flex-col ${className}`} {...props}>
			{children}
		</MuiAccordionDetails>
	);
};

export const Table: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
	return <div className={`flex flex-col gap-2 p-4 ${className || ""}`} {...props} />;
};

export const TableTitle: React.FC<React.HTMLAttributes<HTMLDivElement> & { icon: React.ComponentType<React.SVGProps<SVGSVGElement>> }> = ({
	className,
	icon: Icon,
	children,
	...props
}) => {
	return (
		<>
			<div className={`flex flex-row items-center justify-start ${className || ""}`} {...props}>
				<Icon className="h-4 w-4 mr-2" /> <span className="font-bold uppercase tracking-tight">{children}</span>
			</div>
			<Divider className="text-theme-divider" />
		</>
	);
};

export const TableDetails: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
	return <div className={`flex flex-col gap-3 ${className || ""}`} {...props} />;
};

const useTableDetailsRowStyles = makeStyles({
	root: {
		"&::before": {
			content: '""',
			position: "absolute",
			top: "1rem",
			width: "100%",
			height: 0,
			borderBottom: "2px dotted",
			color: 'var(--theme-text)',
		},
	},
});

export const TableDetailsRow: React.FC<React.HTMLAttributes<HTMLDivElement> & { title: React.ReactNode; details: React.ReactNode }> = ({
	className,
	children,
	title,
	details,
	...props
}) => {
	const classes = useTableDetailsRowStyles();
	const theme = useSettingsStore(s => s.theme);

	const rowBase = (title: string | undefined, details: any) => (
		<div className={`flex flex-row items-start justify-between gap-4 text-gray-700 font-sm relative ${className || ""} ${classes.root}`} {...props}>
			<span className="flex w-80">
				<span className="break-all pr-2 z-10 relative bg-white dark:bg-[#383838] text-theme">{title}</span>
			</span>
			<span className="text-right w-3/6 flex-grow">
				<span className="break-all z-10 relative pl-2 bg-white dark:bg-[#383838] text-theme">{String(details)}</span>
			</span>
		</div>
	);

	if (details instanceof Object) {
		const prefix =
			(details as Object).constructor === Object ? "Object: " : (details as Object).constructor === Array ? `Array(${(details as Array<any>).length}): ` : "";
		return (
			<>
				{rowBase(title, "")}
				{/*<JSONTree data={details} hideRoot theme={ashes} />*/}
				<Highlight {...defaultProps} theme={theme === "dark" ? themeDark : themeLight} code={prefix + JSON.stringify(details, null, 2)} language="jsx">
					{({ className, style, tokens, getLineProps, getTokenProps }) => (
						<pre className={`${className} text-left p-2 overflow-x-scroll`} style={style}>
							{tokens.map((line, i) => (
								<div key={i} {...getLineProps({ line, key: i, className: "table-row" })}>
									<span className="table-cell">
										{line.map((token, key) => (
											<span key={key} {...getTokenProps({ token, key })} />
										))}
									</span>
								</div>
							))}
						</pre>
					)}
				</Highlight>
			</>
		);
	}

	return rowBase(title, details);
};
