import { CheckSvg, RefreshSvg, XSvg } from '@assets/icons';
import { Button } from '@ui';
import { defaultQueryFn } from '@admin/libs/default-query-fn';
import { useMutation } from '@admin/libs/react-query';

interface ReloadButtonProps {
	label: string;
	refreshUrl: string;
	name: string;
	onClick?: () => void;
	status?: 'warning';
}

export const ReloadButton: React.FC<ReloadButtonProps & Omit<React.ComponentProps<typeof Button>, 'status'>> = ({ label, refreshUrl, name, onClick, status, ...props }) => {
	const { isSuccess, isError, isLoading, mutate } = useMutation(
		() => defaultQueryFn({ queryKey: [refreshUrl] }),
		{
			// Skip default error behavior
			onError: () => undefined
		});

	const handleClick = () => {
		mutate();
		onClick?.();
	};

	return (
		<Button
			variant="underlined"
			status={isSuccess ? 'success' : 'default'}
			className={["text-sm p-0", status === 'warning' && 'text-red-500'].join(" ")}
			iconProps={{ className: 'hidden lg:inline-block' }}
			onClick={handleClick}
			loading={isLoading || isSuccess || isError}
			loadingElement={isSuccess ? (
				<span className="text-green-600 flex items-center">
					<CheckSvg className="h-4 w-4 mr-2" />
					Fertig
				</span>
			) : isError ? (
				<span className="text-red-700 flex items-center">
					<XSvg className="h-4 w-4 mr-2" />
					Fehler
				</span>
			) : null}
			{...props}
		>
			{label}
		</Button>
	);
};