import React from "react";

import { Button, Spinner } from "@ui";
import { Container } from "@ui/layouts";
import { ArrowLongRightSvg, FileSvg, RefreshSvg } from "assets/icons";
import NoEntryPng from "assets/icons/png/no-entry.png";
import { useNavigate } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";

type Variants = "loading" | "error" | "404" | "unauthorized";

type FullScreenNotificationProps =
	| {
			variant: Variants;
			resetErrorBoundary?: never;
			hideResetErrorBoundary?: never;
	  }
	| {
			variant: "error";
			resetErrorBoundary?: () => void;
			hideResetErrorBoundary?: boolean;
	  };

export const FullScreenNotification: React.FC<FullScreenNotificationProps> = ({ variant, resetErrorBoundary, hideResetErrorBoundary }) => {
	const navigate = useNavigate();
	let body = null;

	switch (variant) {
		case "loading": {
			body = <Spinner size="large" variant="primary" />;
			break;
		}
		case "error": {
			body = (
				<div className="flex flex-col items-center gap-6 max-w-md text-center">
					<FileSvg className="h-12 w-12 stroke-current text-gray-800 stroke-0 mx-auto" />
					<span className="text-red-500">Es ist ein Fehler aufgetreten! Versuchen Sie es erneut oder wenden Sie sich an einen Administrator.</span>
					{!hideResetErrorBoundary && (
						<Button variant="underlined" iconStart={<RefreshSvg />} className="text-primary" onClick={resetErrorBoundary}>
							Erneut versuchen
						</Button>
					)}
				</div>
			);
			break;
		}
		case "404": {
			body = (
				<Container maxWidth="unset">
					<div className="max-w-6xl grid-cols-2 grid place-items-center gap-8">
						<div className="flex flex-col gap-8">
							<h1 className="text-4xl font-bold tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
								Keine Raumstation zu sehen...
							</h1>
							<span className="text-lg text-theme-light">
								Breite Leere um dich herum, Raumschiff fliegen ist wohl doch nicht so einfach. Hast du die richtigen Koordination übergeben?
							</span>
							<div className="space-x-3">
								<Button iconEnd={<ArrowLongRightSvg />} variant="underlined" className="text-primary" onClick={() => navigate("/dashboard")}>
									Startseite
								</Button>
							</div>
						</div>
						<Player
							autoplay
							loop
							src={"https://assets2.lottiefiles.com/packages/lf20_4DPcyu.json"}
							className="max-w-2xl"
						/>
					</div>
				</Container>
			);
			break;
		}
		case "unauthorized": {
			body = (
				<>
					<span className="text-red-500 text-xl">Sie sind nicht berechtigt diese Seite zu betreten!</span>
					<img src={NoEntryPng} className="h-12 w-12 mx-auto" alt="unauthorized" />
				</>
			);
			break;
		}
	}

	return <div className="h-full grid place-items-center flex-grow align-self-center self-center">{body}</div>;
};
