import { Player } from '@lottiefiles/react-lottie-player';
import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { SelectedDump } from './SelectedDump';

import { Button } from '@admin/ui';
import { BiRightArrowAlt } from 'react-icons/bi';

const _InnerRoutes = () => {
	return (
		<Routes>
			<Route path=":id" element={<SelectedDump />} />
			<Route
				index
				element={
					<div className="flex flex-col items-center justify-center">
						<Player src={'https://assets6.lottiefiles.com/packages/lf20_ypej3gd9.json'} className="h-28 w-28" autoplay loop />
						<h4 className="mt-0">Wählen Sie ein Objekt aus zum Analysieren</h4>
						<Button className="mt-4" iconEnd={<BiRightArrowAlt />}>
							Dump erstellen
						</Button>
					</div>
				}
			/>
		</Routes>
	);
};

export const InnerRoutes = memo(_InnerRoutes);
