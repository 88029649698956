import { isDev } from "@admin/config";
import { queryClient } from "@admin/libs/query-client";
import create, { GetState, SetState } from "zustand";

export type Environments = 'dev' | 'test' | 'live' | 'stage';

export type User = {
	title: string;
	user: string;
	userID: number;
	rules: string;
	env: Environments;
}

export enum Permission {
	// SERVER
	RELOAD_TK     = 'adreloadtk',
	RELOAD_VARS   = 'adreloadvars',

	// IPS & BOTS
	EDIT_IPV4   = 'adeditipv4',
	EDIT_BOTS   = 'adeditbots',
}

export interface UserStore {
	permissions: Permission[],
	/**
	 * Used to check whether the current user has the given permission.
	 * 
	 * @example
	 * ```ts
	 * useUserStore.getState().hasPermission(Permission.REMOVE_BOTS, Permission.EDIT_BOTS)
	 * ```
	 */
	hasPermission: ReturnType<typeof hasPermission>;
	/**
	 * Used to logout the current user.
	 */
	logout: ReturnType<typeof logout>;
	readonly user?: {
		userId: number;
		username: string;
		fullName: string;
		env: Environments
	}
}

function logout(set: SetState<UserStore>, _: GetState<UserStore>) {
	return () => {
		set(state => ({ ...state, user: undefined }));
		queryClient.removeQueries("authenticate");
	}
}

function hasPermission(set: SetState<UserStore>, get: GetState<UserStore>) {
	return (...permissions: (Permission|undefined)[]) => {
		const userPermissions = get().permissions;
		return isDev() || permissions.every(permission => {
			return permission == null ? true : userPermissions.includes(permission)
		});
	}
}

export const useUserStore = create<UserStore>((set, get) => ({
	permissions: [],
	hasPermission: hasPermission(set, get),
	logout: logout(set, get),
}));