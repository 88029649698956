import { ServerSimpleSvg } from '@assets/icons';
import { Container } from '@ui/layouts';
import { useQuery } from '@admin/libs/react-query';
import { useSettingsStore } from '@stores/settings';
import { Permission } from '@stores/user';
import { Server } from './models';

import { Skeleton } from '@mui/material';
import { ReloadButton } from './components/ReloadButton';

interface ServerPageProps {}

export const ServerPage: React.FC<ServerPageProps> = () => {
	const qServer = useQuery<Server[]>('listServer');
	const { disableShouldReloadBotProtectionHint, reloadedServers } = useSettingsStore();

	function handleClick(serverId: string) {
		return () => {
			const newServers = [...reloadedServers];

			if (!newServers.includes(serverId)) {
				newServers.push(serverId);
			}

			useSettingsStore.setState({ showShouldReloadBotProtection: false, reloadedServers: newServers });
		}
	}

	return (
		<Container icon={ServerSimpleSvg} title="Server">
			<div className="space-y-2">
				{!qServer.data ? (
					<>
						{Array(6)
							.fill('')
							.map((_, index) => (
								<div key={index} className="h-12 overflow-hidden flex items-center bg-theme shadow px-2 lg:px-4 gap-3">
									<Skeleton height={16} width={200} animation="wave" variant="rectangular" />
									<div className="flex-grow"></div>
									<Skeleton height={14} width={122} animation="wave" variant="rectangular" />
									<Skeleton height={14} width={122} animation="wave" variant="rectangular" />
									<Skeleton height={14} width={155} animation="wave" variant="rectangular" />
								</div>
							))}
					</>
				) : (
					qServer.data?.map((server, index) => (
						<div key={server.ID} className="h-12 overflow-hidden flex items-center bg-theme shadow px-2 lg:px-4">
							<span className="text-sm lg:text-base font-medium">
								<span className="text-theme-light">{index + 1}.</span> {server.NAME}
							</span>
							<div className="flex-auto"></div>
							<div className="flex items-center gap-2 lg:gap-4 leading-none">
								<ReloadButton
									label="Textkonstanten"
									refreshUrl={`reloadServerConstants&serverid=${server.ID}`}
									permission={Permission.RELOAD_TK}
									name={server.NAME}
								/>
								<ReloadButton
									label="Servervariablen"
									refreshUrl={`reloadServerVariables&serverid=${server.ID}`}
									permission={Permission.RELOAD_VARS}
									name={server.NAME}
								/>
								<ReloadButton
									label="Bot-Schutz-Einträge"
									refreshUrl={`reloadBlockedBots&serverid=${server.ID}`}
									permissions={[Permission.EDIT_IPV4, Permission.EDIT_BOTS]}
									name={server.NAME}
									onClick={handleClick(server.ID)}
									status={
										!disableShouldReloadBotProtectionHint &&
										!reloadedServers.includes(server.ID)
											? 'warning'
											: undefined
									}
								/>
							</div>
						</div>
					))
				)}
			</div>
		</Container>
	);
};
