import { useUserStore } from '@stores/user';
import { toast } from 'react-hot-toast';
import {
	useQuery as useQueryReact,
	useMutation as useMutationReact,
	UseMutationOptions,
	UseMutationResult,
	MutationFunction,
	UseQueryOptions,
	QueryFunction,
	QueryKey,
} from "react-query";
import { defaultQueryFn } from "@libs/default-query-fn";
import { openInternetErrorToast } from "@setup/use-app";

export const onError = (e: any) => {
	if (e?.response?.status === 401) {
		useUserStore.getState().logout();
		return
	}

	if (e?.message === "Network Error") {
		openInternetErrorToast()
		return
	}

	toast.error("Ein Fehler ist aufgetreten... 🚑️")
};



export const usePageQuery = <TResult>(queryKey: QueryKey, opts?: QueryOpts<TResult>) => {
	return useQueryReact<TResult>(
		queryKey as QueryKey,
		{
			suspense: true,
			...opts,
		} as UseQueryOptions<TResult, unknown, TResult, QueryKey>
	);
};

type QueryOpts<TResult> = UseQueryOptions & {
	queryFn?: QueryFunction<TResult, QueryKey>;
};

export const useQuery = <TResult>(queryKey: QueryKey, { queryFn, ...opts }: QueryOpts<TResult> = {}) => {
	return useQueryReact<TResult>(
		queryKey as QueryKey,
		queryFn ? queryFn : (defaultQueryFn as QueryFunction<TResult, QueryKey>),
		{
			onError: onError,
			...opts,
		} as UseQueryOptions<TResult, unknown, TResult, QueryKey>
	);
};

export const useMutation = <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
	mutationFn: MutationFunction<TData, TVariables>,
	opts?: UseMutationOptions<TData, TError, TVariables, TContext>
): UseMutationResult<TData, TError, TVariables, TContext> => {
	return useMutationReact<TData, TError, TVariables, TContext>(mutationFn, {
		onError: onError,
		...opts,
	} as UseMutationOptions<TData, TError, TVariables, TContext>);
};

export * from "react-query";
export * from "@admin/libs/default-query-fn";
