import { Button, renderEditCell, renderHeader, TextFieldFormik } from '@ui';
import { Container } from '@ui/layouts';
import { Permission, useUserStore } from '@stores/user';
import { convert } from '@utils/convert-timestamp-to-string';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { CheckSvg, ShieldSvg } from '@assets/icons';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { object, string } from 'yup';
import { PageBase } from './components/PageBase';
import { dateComparator } from "./components/date-comparator";
import { Bots, AddModalProps, Urls } from "./models";

const urls: Urls = {
	listUrl: 'listBots',
	removeUrl: 'removeBots',
	updateUrl: 'updateBots',
	addUrl: 'addBot',
};

const AddModal: React.FC<AddModalProps> = ({ handleSubmit, onClose }) => {

	return (
		<Formik
			initialValues={{
				UserAgent: '',
			}}
			onSubmit={handleSubmit}
			validationSchema={object({
				UserAgent: string().trim().required('Bitte füllen Sie dieses Feld aus!'),
			})}
		>
			<Form>
				<DialogTitle>
					Neuer User Agent
				</DialogTitle>
				<DialogContent>
					<TextFieldFormik label="User Agent" name="UserAgent" />
				</DialogContent>
				<DialogActions>
					<Button type="button" onClick={onClose}>
						Abbrechen
					</Button>
					<Button type="submit" iconStart={<CheckSvg />} status="success">
						Speichern
					</Button>
				</DialogActions>
			</Form>
		</Formik>
	);
};

interface UserAgentPageProps {}

export const UserAgentPage: React.FC<UserAgentPageProps> = () => {
	const [buttonExtra, setButtonExtra] = useState<React.ReactElement[]>([]);

	return (
		<Container
			icon={ShieldSvg}
			title={
				<>
					Bot-Schutz - <span className="font-semibold">User Agent</span>
				</>
			}
			extra={buttonExtra}
		>
			<PageBase<Bots>
				id="__b_ua"
				addModal={AddModal}
				urls={urls}
				editPermission={Permission.EDIT_BOTS}
				columns={rows => [
					{
						field: 'UserAgent',
						headerName: 'User Agent',
						width: 300,
						editable: true,
						renderEditCell,
						renderHeader,
						preProcessEditCellProps: params => {
							const {
								props: { value },
								id,
							} = params;
							let message = '';

							if (rows.find(i => i.UserAgent === value && i.Id !== id)) {
								message = 'Es ist bereits ein Eintrag vorhanden mit diesem User Agent';
							}

							if (!useUserStore.getState().hasPermission(Permission.EDIT_BOTS)) {
								message = 'Fehlende Berechtigung zum Editieren der Einträge!';
							}

							return { ...params.props, error: !!message, message };
						},
					},
					{ field: 'UpdatedUserTitle', headerName: 'Letzter Bearbeiter', width: 200 },
					{ field: 'CreatedUserTitle', headerName: 'Ersteller', width: 200 },
					{
						field: 'UpdatedDate',
						headerName: 'Zuletzt bearbeitet',
						width: 250,
						renderCell: (params: GridRenderCellParams) => convert(params.value),
						sortComparator: dateComparator,
					},
					{
						field: 'CreatedDate',
						headerName: 'Hinzugefügt',
						width: 250,
						renderCell: (params: GridRenderCellParams) => convert(params.value),
						sortComparator: dateComparator,
					},
				]}
				dataGridlocaleText={{
					noRowsLabel: 'Keine Bots verfügbar',
				}}
				localeText={{
					cardTitle: 'Blockierte Bots',
					changesSnackbarTitle: count => `Sie haben ${count} ungespeicherte ${count === 1 ? 'Änderung' : 'Änderungen'} in der Botliste`,
					addModalTitle: 'Bot hinzufügen',
					addModalAlreadyExists: 'Es ist bereits ein Bot mit diesem User Agent vorhanden!',
				}}
				editButtonExtra={setButtonExtra}
			/>
		</Container>
	);
};
