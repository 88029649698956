/**
 * @description
 * Wird frühestens nach der angegebenen Dauer aufgelöst. Wenn die durch das erste Argument gegebene
 * Promise beispielsweise nur 300ms braucht, bis sie fertig ist, so wird bei dem Standarddelay von
 * 1000ms noch weitere 700ms gewartet, bis die von der Funktion debounce bereitgestellte Promise
 * aufglöst wird.
 *
 * @example
 * await debounce(fetch("xyz.xyz/api"), 3000);
 *
 * @param {Function} promise
 * @param {number} minDelay - Die Mindestdauer nach welcher die Promise aufgelöst wird
 * @returns {Promise<string>} Promise<string>
 */
export const debounce = async <T>(promise: Promise<T>, minDelay: number = 1000): Promise<T> => {
	const timeBefore = Date.now();
	const response = await promise;
	const distance = minDelay - (Date.now() - timeBefore);

	if (distance > 0) {
		return await new Promise(resolve => setTimeout(() => resolve(response), distance));
	}

	return response;
};
