import { useEffect } from "react";
import toast from 'react-hot-toast';

let internetErrorToastId = '';

export function openInternetErrorToast() {
	if (internetErrorToastId) return;

	internetErrorToastId = toast.error('Bitte stelle eine Internetverbindung her!', {
		className: '!bg-red-500 !text-white',
		position: 'top-center',
		duration: Infinity,
	});
}

export function closeInternetErrorToast() {
	if (!internetErrorToastId) return;

	toast.dismiss(internetErrorToastId);
	internetErrorToastId = '';
}

export const useApp = () => {
	useEffect(() => {
		const observer = new MutationObserver(() => {
			const html = document.documentElement;
			const { paddingRight, overflow } = getComputedStyle(document.body);

			if (parseInt(paddingRight) && overflow === 'hidden') {
				html.style.top = `-${window.scrollY}px`;
				html.classList.add('prevent-scroll');
			}

			if (!parseInt(paddingRight) && overflow === 'visible') {
				const scrollY = html.style.top;
				html.style.top = '';
				html.classList.remove('prevent-scroll');
				window.scrollTo(0, parseInt(scrollY || '0') * -1);
			}
		});
		observer.observe(document.body, { attributes: true, attributeFilter: ['style'] });

		window.addEventListener('online', closeInternetErrorToast);
		window.addEventListener('offline', openInternetErrorToast);

		return () => {
			window.removeEventListener('online', closeInternetErrorToast);
			window.removeEventListener('offline', openInternetErrorToast);
		};
	}, []);
}